import PropTypes from 'prop-types';
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';


const ComentariosSoporte = ({ comentariosProblemasSoporte }) => {
    return (
        <Card>

            <span className="pb-2">

                {
                    comentariosProblemasSoporte.map(
                        (comentario, index) => {
                            return (
                                <>

                                    <Row id={index} className="justify-content-between p-2 mb-0">
                                        <Col>
                                            <Flex>
                                                <Link className="d-flex" to="/perfil">
                                                    <Avatar src={"/img/team/team_" + comentario.idUsuario + ".jpg"} size="xl" />

                                                </Link>
                                                <div className="flex-1 align-self-center ms-2 mb-0">

                                                    <p className="mb-1 lh-1">
                                                        <Link className="fw-semi-bold fs--2" to="/perfil">
                                                            {comentario.vNombreUsuario}
                                                        </Link> &bull;
                                                        <span className="mb-0 fs--1  text-500"> {comentario.vIP} &bull; {comentario.vFecha} &bull; <img src="/img/reloj48.png" width={15}></img> {comentario.vHora} &bull; {' '}</span>

                                                    </p>
                                                    <p className="mb-0  fs--1">
                                                        <Row>

                                                            <Col className="col-12">
                                                                <SoftBadge
                                                                    bg={"danger"}
                                                                >
                                                                    Problema
                                                                </SoftBadge>
                                                                &nbsp;<span dangerouslySetInnerHTML={{ __html: comentario.vProblema }} />
                                                            </Col>
                                                            <Col className="col-12 ">
                                                                <Link className="fw-semi-bold fs--2" to="#">
                                                                    Resolver
                                                                </Link>
                                                            </Col>

                                                        </Row>
                                                        
                                                    </p>
                                                </div>

                                            </Flex>
                                        </Col>

                                        <Col xs="auto">
                                            {/* <FeedDropdown /> */}
                                        </Col>
                                    </Row>

                                   
                                </>
                            )

                        }
                    )
                }
            </span>

        </Card>
    );
};

ComentariosSoporte.propTypes = {
    comentariosProblemasSoporte: PropTypes.array
};

export default ComentariosSoporte;
