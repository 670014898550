
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, InputGroup, Spinner, OverlayTrigger, Tooltip, ToggleButton, CloseButton, Card } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Event from 'akasia-components/eventos/Event';
import FalconCloseButton from 'components/common/FalconCloseButton';
import FeedProvider from '../orden/comentarios/FeedProvider';
import FeedCard from '../orden/comentarios/FeedCard';
import useToken from 'hooks/useToken';
import CustomDateInput from 'components/common/CustomDateInput';
import DatePicker from 'react-datepicker';
import ModalClienteGanado from './ModalClienteGanado';



const ModalSeguimientoLead = ({
    showModal,
    setShowModal,
    contactos,
    setContactos,
    lista3LeadsLiberados,
    listaEventos,
    setListaEventos,
    setProximoEvento,
    proximoEvento,
    listaEventosPasados,
    setListaEventosPasados,
    listaEventosHoy,
    setListaEventosHoy,
    listaEventosFuturos,
    setListaEventosFuturos,
    listaLeadsLiberados,
    setListaLeadsLiberados,
    mostrarLeads,
    Filter_Tags,
    filtroEventosVencidos,
    setMensajeError,
    setShowMensajeError,
    setMensajeInformativo,
    setShowMensajeInformativo,
    idLead,
    setIdLead,
    leadsVinculados,
    setLeadsVinculados
    //setModalAbierto 
}) => {


    const { token, setToken, idUsuario, nombreCompleto } = useToken();


    const [showEtapaClienteGanadoModal, setShowEtapaClienteGanadoModal] = useState(false);//state para cuando el cliente cambie a no interesado
    const [vNombreEtapa, setvNombreEtapa] = useState(null);

    const [cargandoModal, setCargandoModal] = useState(false);
    const [detallesLead, setDetallesLead] = useState({});

    const [habilitarBtnLiberarLead, setHabilitarBtnLiberarLead] = useState(true);
    const [listaJsonLeadsVinculados, setListaJsonLeadsVinculados] = useState([]);
    const [showModalLiberarLeads, setShowModalLiberarLeads] = useState(false);
    const [habilitarBtnContinuarLiberarLead, setHabilitarBtnContinuarLiberarLead] = useState(true);
    const [showModalAsignarmeLeadsVinculados, setShowModalAsignarmeLeadsVinculados] = useState(false);
    const [habilitarBtnAsignarLead, setHabilitarBtnAsignarLead] = useState(true);
    const [showModalComentarioCambioVendedor, setShowModalComentarioCambioVendedor] = useState(false);
    
    const [procesandoDesvincularLeadCliente, setProcesandoDesvincularLeadCliente] = useState(false);
    const [procesandoModal, setProcesandoModal] = useState(false);
    const [Modal_NombreLead, setModal_NombreLead] = useState("");
    const [editarGiroComercial, setEditarGiroComercial] = useState(false);
    const [eventosActivos, setEventosActivos] = useState([]);

    const [Modal_GiroComercial, setModal_GiroComercial] = useState("");
    const [Modal_Correo, setModal_Correo] = useState("");
    const [Modal_PropuestaEconomica, setModal_PropuestaEconomica] = useState(0);
    const [Modal_Moneda, setModal_Moneda] = useState("");

    const [Modal_BitAddTag, setModal_BitAddTag] = useState(false);
    const [Modal_JsonTags, setModal_JsonTags] = useState([]);
    const [Modal_valueTagSelected, setModal_valueTagSelected] = useState(-1);
    const [habilitarVinculacion, setHabilitarVinculacion] = useState(0);
    const [idLeadVincular, setIdLeadVincular] = useState("");

    const [editarNombreLead, setEditarNombreLead] = useState(false);
    const [editarCorreo, setEditarCorreo] = useState(false);
    const [editarPropuestaEconomica, setEditarPropuestaEconomica] = useState(false);

    const [editarEtapaModal, setEditarEtapaModal] = useState(false);

    const [TagsForModalDDL, SetTagsForModalDDL] = useState([]);

    const [habilitarBtnTerminarEvento, setHabilitarBtnTerminarEvento] = useState(true);

    const [editarEvento, setEditarEvento] = useState(false);

    const [formDataEvent, setFormDataEvent] = useState({
        dFechaEvento: new Date(),
        vTituloEvento: '',
        vDescripcionEvento: '',
        idLead: 0,
        idUser: 0,
        idLeadEvento: 0
    });

    const [showAgregarEvento, setShowAgregarEvento] = useState(false);

    const [procesandoVincular, setProcesandoVincular] = useState(false);
    const [procesandoDesvincular, setProcesandoDesvincular] = useState(false);

    const [comentarios, setComentarios] = useState([]);
    const [comentario, setComentario] = useState('');
    const [procesandoComentario, setProcesandoComentario] = useState(false);

    const [showWarningEventoRepetido, setShowWarningEventoRepetido] = useState(false);

    const [eventosMismaHora, setEventoMismaHora] = useState([]);

    const [comentarioCambioVendedor, setComentarioCambioVendedor] = useState('');

    const [habilitarBtnGuardarComentario, setHabilitarBtnGuardarComentario] = useState(true);

    const [habilitarBtnEvent, setHabilitarBtnEvent] = useState(true);

    const [motivoNoInteresado, setMotivoNoInteresado] = useState('');
    const [showEtapaNoInteresadoModal, setShowEtapaNoInteresadoModal] = useState(false);
    const [habilitarBtnGuardar, setHabilitarBtnGuardar] = useState(true);
    const [catEtapas, setCatEtapas] = useState([]);
    const [tags, setTags] = useState([]);
    const [vendedor, setVendedor] = useState(0);

    


    useEffect(() => {
        ObtenerDatosLead(idLead)

    }, [showModal]);

    /**
     * Obtiene los datos de un lead en especifico y los muestra en un modal
     * @param {any} idLead
     */
    const ObtenerDatosLead = async (idLead) => {

        if (idLead <= 0) { //cuando se abre contactos (o cualquier componente donde se use este modal) intenta venir a ObtenerDatosLead, este if evita que ejecute la función si el idLead es menor igual a cero
            return;
        }

        //setModalAbierto("modalSeguimientoLead");
        

        var idVendedor_ = sessionStorage.getItem("selectIDUsuario") == null ? (vendedor == 0) ? idUsuario : vendedor : sessionStorage.getItem("selectIDUsuario");
        setVendedor(idVendedor_);

        setCargandoModal(true);
        setProcesandoModal(true);
        setEditarNombreLead(false);
        setHabilitarBtnEvent(true);


        //el state nombreVendedor lo vamos a usar mas abajo para guardar el cuerpo de una notificacion
        
        //console.log("nombre del que incio sesión: ", sessionStorage.getItem("vUsuario"));


        const response = await fetch("/api/leads/ObtenerDetallesLead/?idLead=" + idLead,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            console.log("==== OBTENER DATOS LEAD ====");

            console.log("response: ",response);
            const data = await response.json();
            console.log("data: ",data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("ObjDetalles:", ObjDetalles);

                console.log("tabla 2: ", ObjDetalles["table2"][0]);
         
                //Se cargan las variables iniciales
                setModal_GiroComercial(ObjDetalles["table2"][0].vGiroComercial);
                setModal_NombreLead(ObjDetalles["table2"][0].vNombreLead);
                setModal_Correo(ObjDetalles["table2"][0].vCorreo);
                setModal_PropuestaEconomica(ObjDetalles["table2"][0].dPropuestaEconomica);
                setModal_Moneda(ObjDetalles["table2"][0].vMoneda);
                setTags(ObjDetalles["table5"]);
                setCatEtapas(ObjDetalles["table6"]);
                var leadsVinculados = JSON.parse(ObjDetalles["table2"][0].vJsonLeadsVinculados);

                const newArray = leadsVinculados.filter(e => e.idLead !== ObjDetalles["table2"][0].idLead);

                console.log("leads vinculados: ", newArray);
                setListaJsonLeadsVinculados(newArray);

                setFormDataEvent({
                    ...formDataEvent,
                    idLead: idLead
                });

                setDetallesLead(ObjDetalles["table2"][0]);
                console.log("detalles Lead: ", ObjDetalles["table2"][0]);

                setComentarios(ObjDetalles["table3"]);
                setEventosActivos(ObjDetalles["table4"]);
                SetTagsForModalDDL(ObjDetalles["table5"]);
                console.log("tags:", ObjDetalles["table5"]);

                if (ObjDetalles["table2"][0].vJsonTags != null) {
                    //ObjDetalles["table2"][0].ObjJsonTags = JSON.parse(ObjDetalles["table2"][0].vJsonTags);
                    var tagsList = JSON.parse(ObjDetalles["table2"][0].vJsonTags);
                    
                    if (tagsList.length > 0) {

                        setModal_JsonTags(JSON.parse(ObjDetalles["table2"][0].vJsonTags));
                        console.log("Tags Asignados", tagsList);

                        var TagsToSelect_Copy = [];

                        ObjDetalles["table5"].map((tag) => {

                            let matched = tagsList.filter(tl => tl.idLeadTag === tag.idLeadTag);

                            if (matched.length) {
                                //
                            } else {
                                TagsToSelect_Copy.push(tag)
                            }

                        })

                        console.log("Tags para el DDL: ", TagsToSelect_Copy);
                        SetTagsForModalDDL(TagsToSelect_Copy);
                    }
                }
                
            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setCargandoModal(false);
        setProcesandoModal(false);
    }

    /**
     * Libera un lead para que cualquier vendedor lo pueda tomar sin importar si ya pasaron 5 dias o no
     */
    const liberarLead = async () => {
        setHabilitarBtnLiberarLead(false); //este estado hace referencia al boton "liberar lead en el modal del seguimiento del contacto/lead"
        setHabilitarBtnContinuarLiberarLead(false);// este al boton "continuar" del modal que se abre cuando un lead tiene leads vinculados

        var jDataSend = {};
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/liberarLead/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== LIBERAR LEAD ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                setHabilitarBtnLiberarLead(true);
                setHabilitarBtnContinuarLiberarLead(true);
                detallesLead.bRelease = true;

                GuardarComentario("El Lead ha sido liberado", 2, detallesLead.idLead);

                //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                //guardar la notificacion de que se libero un lead
                guardarNotificacion("Se liberó Lead", "<strong>" + nombreCompleto + "</strong> libero el lead <strong>#" + detallesLead.idLead + "</strong>", 0,objToActionString,1);
                
                
                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    console.log("currentLeadIndex: ", currentLeadIndex);
                    if (currentLeadIndex == -1) {//si el lead abierto en el modal no esta en la lista de contactos
                        setContactos([detallesLead, ...contactos]);
                    } else {
                        contactos[currentLeadIndex].iDaysDiff = 0;
                    }

                    if (listaJsonLeadsVinculados.length > 0) {
                        listaJsonLeadsVinculados.map((lead) => {

                            GuardarComentario("El Lead ha sido liberado", 2, lead.idLead);

                            currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                            console.log(currentLeadIndex);
                            if (currentLeadIndex == -1) {//si el lead abierto en el modal no esta en la lista de contactos
                                setContactos([detallesLead, ...contactos]);
                            } else {
                                contactos[currentLeadIndex].iDaysDiff = 0;
                            }

                        })
                    }
                }

                //ocultar el modal de libear un lead con leads vinculados
                setShowModalLiberarLeads(false);

               

            } else {

                setHabilitarBtnLiberarLead(true);
                setHabilitarBtnContinuarLiberarLead(true);

                setMensajeError(data.vMessage);
                setShowMensajeError(true);

            }

        }
        else {
            setHabilitarBtnLiberarLead(true);
            setHabilitarBtnContinuarLiberarLead(true);

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    /**
     * Actualiza el Nombre Lead del lead seleccionado
     */
    const actualizarNombreLead = async () => {


        var jDataSend = {};

        jDataSend.vNombreLead = Modal_NombreLead;
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/actualizarNombreLead/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== Update Nombre Lead ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log(detalles);

                GuardarComentario("Modificó el nombre del lead de <b>" + detallesLead.vNombreLead + "</b> a <b>" + detalles.vNombreLead + "</b>", "2", detallesLead.idLead);

                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {

                        var detalleslead_Copy = detallesLead;
                        detalleslead_Copy.vNombreLead = detalles.vNombreLead;

                        setContactos([detalleslead_Copy, ...contactos]);

                    } else {

                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].vNombreLead = detalles.vNombreLead;
                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead cambió su nombre.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1,'', 1, detallesLead.idLead);
                    guardarNotificacion("Cambio de nombre de Lead",
                        "<b> " + nombreCompleto + "</b> ha cambiado el nombre del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + Modal_NombreLead.substring(0, 3) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2);
                }

                setDetallesLead({
                    ...detallesLead,
                    vNombreLead: detalles.vNombreLead,
                    iDaysDiff: 0
                });

                setEditarNombreLead(false);
                setMensajeInformativo("Cambio de Nombre de Lead exitoso");
                setShowMensajeInformativo(true);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * Actualiza el Giro comercial del lead seleccionado
     */
    const actualizarGiroComercial = async () => {


        var jDataSend = {};

        jDataSend.vGiroComercial = Modal_GiroComercial;
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/actualizarGiroComercial/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== Update Giro Comercial Etapa ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log(detalles);

                GuardarComentario("Modificó el giro comercial de <b>" + detallesLead.vGiroComercial + "</b> a <b>" + detalles.vGiroComercial + "</b>", "2", detallesLead.idLead);

                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {
                        var detalleslead_Copy = detallesLead;
                        detalleslead_Copy.vGiroComercial = detalles.vGiroComercial;

                        setContactos([detalleslead_Copy, ...contactos]);
                    } else {

                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].vGiroComercial = detalles.vGiroComercial;

                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead cambió su giro comercial.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Cambio de giro comercial de Lead",
                        "<b> " + nombreCompleto + "</b> ha cambiado el giro comercial del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + Modal_GiroComercial.substring(0, 1) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2);
                }

                setDetallesLead({
                    ...detallesLead,
                    vGiroComercial: detalles.vGiroComercial,
                    iDaysDiff: 0
                });

                setEditarGiroComercial(false);
                setMensajeInformativo("Cambio de Giro Comercial exitoso");
                setShowMensajeInformativo(true);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }



        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * Actualiza el Correo del lead seleccionado
     */
    const actualizarCorreo = async () => {


        var jDataSend = {};

        jDataSend.vCorreo = Modal_Correo;
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/actualizarCorreo/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== Update Correo ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log(detalles);


                GuardarComentario("Modificó el correo de <b>" + detallesLead.vCorreo + "</b> a <b>" + detalles.vCorreo + "</b>", "2", detallesLead.idLead);

                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {
                        var detalleslead_Copy = detallesLead;
                        detalleslead_Copy.vCorreo = detalles.vCorreo;

                        setContactos([detalleslead_Copy, ...contactos]);
                    } else {

                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].vCorreo = detalles.vCorreo;

                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead cambió su correo.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Cambio de correo de Lead",
                        "<b> " + nombreCompleto + "</b> ha cambiado el correo del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + Modal_Correo.substring(0, 6) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2);
                }

                setDetallesLead({
                    ...detallesLead,
                    vCorreo: detalles.vCorreo,
                    iDaysDiff: 0
                });

                setEditarCorreo(false);
                setMensajeInformativo("Cambio de Correo exitoso");
                setShowMensajeInformativo(true);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * Actualiza la propuesta eocnómica
     */
    const actualizarPropuestaEconomica = async () => {

        /**Si no selecciona moneda no procede*/
        if (Modal_Moneda === "") {

            setMensajeError("Selecciona una Moneda para guardar la propuesta economica");
            setShowMensajeError(true);

        } else {

            var jDataSend = {};

            jDataSend.dPropuestaEconomica = Modal_PropuestaEconomica;
            jDataSend.vMoneda = Modal_Moneda;
            jDataSend.idLead = detallesLead.idLead;

            const response = await fetch("/api/leads/actualizarPropuestaEconomica/",
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jDataSend)
                }).catch((error) => {

                    setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                    setShowMensajeError(true);
                    console.log('Hubo un problema con la peticion Fetch:' + error);
                });

            if (response.ok) {

                console.log("==== Update Propuesta ====");
                console.log(response);
                const data = await response.json();
                console.log(data);

                if (data.iResponseType == 1) {

                    var detalles = JSON.parse(data.detalles);
                    console.log(detalles);


                    GuardarComentario("Modificó la propuesta económica de <b>" + detallesLead.dPropuestaEconomica + " " + detallesLead.vMoneda + "</b> a <b>" + detalles.dPropuestaEconomica + " " + detalles.vMoneda + "</b>", "2", detallesLead.idLead);

                    if (contactos !== null && typeof contactos !== 'undefined') {
                        var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                        if (currentLeadIndex == -1) {
                            var detalleslead_Copy = detallesLead;
                            detalleslead_Copy.dPropuestaEconomica = detalles.dPropuestaEconomica;
                            detalleslead_Copy.vMoneda = detalles.vMoneda;

                            setContactos([detalleslead_Copy, ...contactos]);
                        } else {

                            contactos[currentLeadIndex].iDaysDiff = 0;
                            contactos[currentLeadIndex].dPropuestaEconomica = detalles.dPropuestaEconomica;
                            contactos[currentLeadIndex].vMoneda = detalles.vMoneda;

                        }
                    }

                    //Guarda una notificación si alguien que no es el vendedor de este lead cambió su propuesta economica.
                    if (idUsuario != detallesLead.idUsuario) {

                        //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                        var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                        guardarNotificacion("Cambio de propuesta economica de Lead",
                            "<b> " + nombreCompleto + "</b> ha cambiado la P.E. del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + Modal_PropuestaEconomica.substring(0, 4) + " " + Modal_Moneda + "'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2);
                    }

                    setDetallesLead({
                        ...detallesLead,
                        dPropuestaEconomica: detalles.dPropuestaEconomica,
                        vMoneda: detalles.vMoneda,
                        iDaysDiff: 0
                    });

                    setEditarPropuestaEconomica(false);


                    setMensajeInformativo("Cambio de Propuesta Económica exitoso");
                    setShowMensajeInformativo(true);



                } else {
                    setShowMensajeError(true);
                    setMensajeError(data.vMessage);
                }



            }
            else {
                setShowMensajeError(true);
                setMensajeError(response.status + ' ' + response.statusText);
                if (response.status == 401) {
                    setToken(null);
                    location.href = "";
                }
                console.log(response);
            }

        }


    }

    /**
     * Actualiza el Json Tags del lead seleccionado
     */
    const actualizarJsonTags = async (updatedTags, objDDLTag) => {


        var jDataSend = {};

        jDataSend.vJsonTags = updatedTags;
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/actualizarJsonTags/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== Update Json Tags ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                GuardarComentario("Modificó el tag <b>" + objDDLTag.vNombreTag + "</b>", "2", detallesLead.idLead);

                var detalles = JSON.parse(data.detalles);
                console.log(detalles);

                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {

                        var detalleslead_Copy = detallesLead;
                        detalleslead_Copy.vJsonTags = data.detalles;
                        detalleslead_Copy.ObjJsonTags = detalles;

                        setContactos([detalleslead_Copy, ...contactos]);
                    } else {

                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].vJsonTags = data.detalles;
                        contactos[currentLeadIndex].ObjJsonTags = detalles;
                    }
                }

                if (lista3LeadsLiberados !== null && lista3LeadsLiberados !== undefined) {

                    var currentLeadLiberado = lista3LeadsLiberados.findIndex((l) => l.idLead == detallesLead.idLead);

                    if (currentLeadLiberado > -1) {
                        lista3LeadsLiberados[currentLeadLiberado].vJsonTags = data.detalles;
                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead cambió sus etiquetas.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Cambio de etiqueta de Lead",
                        "<b> " + nombreCompleto + "</b> ha cambiado las etiquetas del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + objDDLTag.vNombreTag.substring(0, 5) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );
                }


                setDetallesLead({
                    ...detallesLead,
                    vJsonTags: data.detalles,
                    ObjJsonTags: detalles,
                    iDaysDiff: 0
                });

                setModal_JsonTags(detalles);

                var TagsToSelect_Copy = [];

                tags.map(function (a) {

                    let matched = detalles.filter(b => a.idLeadTag === b.idLeadTag);

                    if (!matched.length) {

                        TagsToSelect_Copy.push(a)
                    }

                })

                console.log(TagsToSelect_Copy);
                SetTagsForModalDDL(TagsToSelect_Copy);


                setMensajeInformativo("Tag " + objDDLTag.vNombreTag + " modificado");
                setShowMensajeInformativo(true);


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);

            }



        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
      * Valida un evento repetido
      * @param {any} data
      */
    const ValidarEventoRepetido = async (formData) => {



        if (formData.vTituloEvento == null || formData.vTituloEvento == '') {
            setMensajeError("El titulo es necesario");
            setShowMensajeError(true);
            return;
        }
        setHabilitarBtnEvent(false);

        var newDate_ = new Date(formData.dFechaEvento).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12:false
        });

        newDate_ = newDate_.replace(",", "");
        formData.dFechaEventoReal = newDate_;
        formData.idUsuario = idUsuario;
        console.log("---------FORM DATA---------");
        console.log(formData);
        //console.log(formData.dFechaEvento)
        //console.log(newDate_)

        var jsonFormData = JSON.stringify(formData);
        const responseEventoRepetido = await fetch("/api/leads/validarEventoRepetido",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jsonFormData
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (responseEventoRepetido.ok) {
            console.log("==== VALIDA EVENTOS A LA MISMA HORA  ====");
            console.log(responseEventoRepetido);

            const dataEventoRepetido = await responseEventoRepetido.json();
            console.log(dataEventoRepetido);

            if (dataEventoRepetido.iResponseType == 1) {
                
                RegistrarEvento(formData);

            }
            else if (dataEventoRepetido.iResponseType == 3) {

                var lEventosMismaHora = JSON.parse(dataEventoRepetido.detalles);
                console.log(lEventosMismaHora);

                setEventoMismaHora(lEventosMismaHora);
                setShowWarningEventoRepetido(true);
                setShowAgregarEvento(false);
                setHabilitarBtnEvent(true);

            }
            else {

                setMensajeError(dataEventoRepetido.vMessage);
                setShowMensajeError(true);
                setHabilitarBtnEvent(true);
            }
        }
        else {
            setShowMensajeError(true);
            setMensajeError(responseEventoRepetido.status + ' ' + responseEventoRepetido.statusText);
            if (responseEventoRepetido.status == 401) {
                setToken(null);
                location.href = "";
            }
            setHabilitarBtnEvent(true);
            console.log(responseEventoRepetido);
        }


    };

    /**
     * Registra el evento
     * @param {any} formData
     */
    const RegistrarEvento = async (formData) => {

        setHabilitarBtnEvent(false);

        var newDate_ = new Date(formData.dFechaEvento).toLocaleDateString('es-MX', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false
        });

        newDate_ = newDate_.replace(",", "");
        formData.dFechaEventoReal = newDate_;
        formData.idUser = vendedor;
        console.log("formData: ", formData);
        var jsonFormData = JSON.stringify(formData);


        const response = await fetch("/api/leads/registrarEvento",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jsonFormData
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== Registrar Evento ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log("objDetalles: ", ObjDetalles);

                var ObjEventoCreado = ObjDetalles["table2"][0];

                var date_ = new Date(formData.dFechaEvento).toLocaleDateString('es-mx', {
                    weekday: 'long', year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit'
                });

                //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);

                if (editarEvento == false) { //si se creó un evento

                    GuardarComentario("&#x1F7E2;Agregó un evento: <b>" + formData.vTituloEvento + "</b> Descripción: <b>" + formData.vDescripcionEvento + "</b>. Programado para el <b>" + date_ + "</b>", "2", detallesLead.idLead);

                    setMensajeInformativo("Se ha registrado un evento");
                    setShowMensajeInformativo(true);

                    setEventosActivos([ObjEventoCreado, ...eventosActivos]);

                    if (listaEventos !== null && listaEventos !== undefined) {
                        setListaEventos([ObjEventoCreado, ...listaEventos]);
                    }

                    //Guarda una notificación si alguien que no es el vendedor de este lead creo un evento.
                    if (idUsuario != detallesLead.idUsuario) {

                        guardarNotificacion("Evento con un lead creado",
                            "<b> " + nombreCompleto + "</b> ha creado un evento para el lead <b>#" + detallesLead.idLead + "</b> : <i> '" + formData.vTituloEvento.substring(0, 3) + "...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2);
                    }

                } else { //si se editó un evento

                    GuardarComentario("&#x1F7E2;Editó un evento: <b>" + formData.vTituloEvento + "</b> Descripción: <b>" + formData.vDescripcionEvento + "</b>. Programado para el <b>" + date_ + "</b>", "2", detallesLead.idLead);

                    setMensajeInformativo("Se ha editado un evento");
                    setShowMensajeInformativo(true);

                    var currentEventIndex = eventosActivos.findIndex((ea) => ea.idLeadEvento === ObjEventoCreado.idLeadEvento);

                    if (currentEventIndex >= 0) {

                        eventosActivos[currentEventIndex].vTituloEvento = ObjEventoCreado.vTituloEvento;
                        eventosActivos[currentEventIndex].vDescripcionEvento = ObjEventoCreado.vDescripcionEvento;
                        eventosActivos[currentEventIndex].dFechaEvento = ObjEventoCreado.dFechaEvento;

                    }

                    if (listaEventos !== null && listaEventos !== undefined) {

                        currentEventIndex = listaEventos.findIndex((le) => le.idLeadEvento === ObjEventoCreado.idLeadEvento);

                        if (currentEventIndex >= 0) {

                            listaEventos[currentEventIndex].vTitutloEvento = ObjEventoCreado.vTituloEvento;
                            listaEventos[currentEventIndex].vDescripcionEvento = ObjEventoCreado.vDescripcionEvento;
                            listaEventos[currentEventIndex].dFechaEvento = ObjEventoCreado.dFechaEvento;
                        }
                    }

                    //Guarda una notificación si alguien que no es el vendedor de este lead creo un evento.
                    if (idUsuario != detallesLead.idUsuario) {

                        guardarNotificacion("Evento con un lead editado",
                            "<b> " + nombreCompleto + "</b> ha editado un evento con el lead <b>#" + detallesLead.idLead + "</b> : <i> '" + formData.vTituloEvento.substring(0, 3) + "...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2);
                    }

                }

                if (contactos !== null && typeof contactos !== 'undefined') {

                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) { //si el lead no esta en la tabla de contacos, agregalo
                        var detalleslead_Copy = detallesLead;
                        detalleslead_Copy.iDaysDiff = 0;

                        setContactos([detalleslead_Copy, ...contactos]);
                    } else {
                        contactos[currentLeadIndex].iDaysDiff = 0;
                    }
                }

                if (proximoEvento !== null && proximoEvento !== undefined) {
                    setProximoEvento(ObjDetalles["table3"]);
                }

                setDetallesLead({
                    ...detallesLead,
                    iDaysDiff: 0
                });

                setShowAgregarEvento(false);
                setShowWarningEventoRepetido(false);
                setHabilitarBtnEvent(true);
                setEditarEvento(false);

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setHabilitarBtnEvent(true);
            }
        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
            setHabilitarBtnEvent(true);
        }
    };

    /**
     * Guarda los comentarios
     * @param {any} vComentario
     * @param {any} iTipoCommentario
     * @param {any} _idLead
     */
    const GuardarComentario = async (vComentario, iTipoCommentario, _idLead) => {

        setProcesandoComentario(true);
        setProcesandoModal(true);

        const response = await fetch("/api/leads/RegistrarComentario?idLead=" + _idLead +
            "&vComentario=" + encodeURIComponent(vComentario) + "&idTipoComentario=" + encodeURIComponent(iTipoCommentario), {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            setShowMensajeError(true);
            setMensajeError('Hubo un problema con la peticion Fetch:' + error);
            console.log('Hubo un problema con la peticion Fetch:' + error);
        });

        if (response.ok) {

            console.log(" ==== Registrar comentarios ==== ");
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                console.log(ObjDetalles);

                setProcesandoComentario(false);
                setProcesandoModal(false);
                setComentario('');

                setComentarios([ObjDetalles, ...comentarios]);

                if (detallesLead.idLead != null && iTipoCommentario == 1) {

                    if (contactos !== null && typeof contactos !== 'undefined') {
                        var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                        if (currentLeadIndex == -1) {
                            var detalleslead_Copy = detallesLead;
                            detalleslead_Copy.iDaysDiff = 0;

                            setContactos([detalleslead_Copy, ...contactos]);
                        } else {

                            contactos[currentLeadIndex].iDaysDiff = 0;

                        }
                    }

                    setDetallesLead({
                        ...detallesLead,
                        iDaysDiff: 0
                    });
                }

                //guardarNotificacion("Comentario", nombreUsuario + " hizo un comentario en el lead #" + detallesLead.idLead, 0);

            } else {

                setShowMensajeError(true);
                setMensajeError(data.vMessage);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
        setProcesandoComentario(false);
        setProcesandoModal(false);
        setComentario('');
    }

    /**
     * Actualiza el evento
     * @param {any} idLeadEvento
     * @param {any} resultadoEvento
     */
    const TerminarEvento = async (idLeadEvento, resultadoEvento) => {

        setProcesandoModal(true);
        setHabilitarBtnTerminarEvento(false);
        if (resultadoEvento == null || resultadoEvento == '') {
            setMensajeError("Es necesario que escriba el resultado del evento");
            setShowMensajeError(true);
            setProcesandoModal(false);
        } else {
            //alert(JSON.stringify(idLeadEvento))
            const response = await fetch("/api/leads/TerminarEvento/?idLeadEvento=" + idLeadEvento + "&vResultado=" + resultadoEvento,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }).catch((error) => {

                    setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                    setShowMensajeError(true);
                    console.log('Hubo un problema con la peticion Fetch:' + error);
                });

            if (response.ok) {
                console.log("==== Terminar Evento ====");
                console.log(response);
                const data = await response.json();
                console.log(data);

                if (data.iResponseType == 1) {

                    /**Se muestra mensaje informativo */
                    setMensajeInformativo("Se ha marcado el evento como terminado.");
                    setShowMensajeInformativo(true);

                    /** Se guarda el comentario*/
                    GuardarComentario("&#x26D4;Terminó el evento con el siguiente resultado: <b>" + resultadoEvento + "</b>", "2", detallesLead.idLead);


                    /**Se remueve el evento terminado de la lista de eventos del lead seleccionado(modal lead)*/
                    var currentEventIndex = eventosActivos.findIndex((e) => e.idLeadEvento === idLeadEvento);

                    if (currentEventIndex >= 0) {

                        const newEventosActivos = [
                            ...eventosActivos.slice(0, currentEventIndex),
                            ...eventosActivos.slice(currentEventIndex + 1)
                        ];

                        setEventosActivos(newEventosActivos);

                    }

                    if (proximoEvento !== null && proximoEvento !== undefined) {
                        //se borra el evento de la lista de eventos que se muestra en la parte superior proximoEvento
                        var currentEventIndexProximoEvento = proximoEvento.findIndex((e) => e.idLeadEvento === idLeadEvento);

                        if (currentEventIndexProximoEvento >= 0) {

                            const newProximoEvento = [
                                ...proximoEvento.slice(0, currentEventIndexProximoEvento),
                                ...proximoEvento.slice(currentEventIndexProximoEvento + 1)
                            ];

                            setProximoEvento(newProximoEvento);

                        }
                    }

                    if (listaEventos !== null && listaEventos !== undefined) {
                        /**Se remueve el evento terminado de los eventos proximos de todos los leads*/
                        var currentListEventIndex = listaEventos.findIndex((e) => e.idLeadEvento === idLeadEvento);

                        if (currentListEventIndex >= 0) {

                            const newListaEventosActivos = [
                                ...listaEventos.slice(0, currentListEventIndex),
                                ...listaEventos.slice(currentListEventIndex + 1)
                            ];

                            setListaEventos(newListaEventosActivos);

                        }
                    }

                    //Se remueve el evento de la lista de eventos que se muestra en le modal Eventos
                    //si el evento esta vencido
                    if (listaEventosPasados !== null && listaEventosPasados !== undefined) {
                        var eventoPasadoIndex = listaEventosPasados.findIndex((e) => e.idLeadEvento == idLeadEvento)
                        if (eventoPasadoIndex >= 0) {

                            const newListaEventosPasados = [
                                ...listaEventosPasados.slice(0, eventoPasadoIndex),
                                ...listaEventosPasados.slice(eventoPasadoIndex + 1)
                            ]

                            setListaEventosPasados(newListaEventosPasados);
                        }
                    }

                    //si el evento es de hoy
                    if (listaEventosHoy !== null && listaEventosHoy !== undefined) {
                        var eventoHoyIndex = listaEventosHoy.findIndex((e) => e.idLeadEvento == idLeadEvento)
                        if (eventoHoyIndex >= 0) {

                            const newListaEventosHoy = [
                                ...listaEventosHoy.slice(0, eventoHoyIndex),
                                ...listaEventosHoy.slice(eventoHoyIndex + 1)
                            ]

                            setListaEventosHoy(newListaEventosHoy);
                        }
                    }

                    //si el evento es futuro
                    if (listaEventosFuturos !== null && listaEventosFuturos !== undefined) {
                        var eventoFuturoIndex = listaEventosFuturos.findIndex((e) => e.idLeadEvento == idLeadEvento)
                        if (eventoFuturoIndex >= 0) {

                            const newListaEventosFuturos = [
                                ...listaEventosFuturos.slice(0, eventoFuturoIndex),
                                ...listaEventosFuturos.slice(eventoFuturoIndex + 1)
                            ]

                            setListaEventosFuturos(newListaEventosFuturos);
                        }
                    }


                    if (contactos !== null && typeof contactos !== 'undefined') {

                        var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);
                        if (currentLeadIndex == -1) {
                            var detalleslead_Copy = detallesLead;
                            detalleslead_Copy.iDaysDiff = 0;

                            setContactos([detalleslead_Copy, ...contactos]);
                        } else {

                            contactos[currentLeadIndex].iDaysDiff = 0;

                        }

                    }

                    //Guarda una notificación si alguien que no es el vendedor de este lead termino un evento.
                    if (idUsuario != detallesLead.idUsuario) {

                        //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                        var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                        guardarNotificacion("Evento de un lead termiando",
                            "<b> " + nombreCompleto + "</b> ha terminado un evento del lead <b>#" + detallesLead.idLead + "</b> : <i> '" + resultadoEvento.substring(0, 5) + "...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2
                        );
                    }

                    setDetallesLead({
                        ...detallesLead,
                        iDaysDiff: 0
                    });


                    setProcesandoModal(false);
                    setHabilitarBtnTerminarEvento(true);
                }
                else {
                    setMensajeError(data.vMessage);
                    setShowMensajeError(true);
                    setProcesandoModal(false);
                    setHabilitarBtnTerminarEvento(true);
                }

            }
            else {
                setProcesandoModal(false);
                setShowMensajeError(true);
                setHabilitarBtnTerminarEvento(true);
                setMensajeError(response.status + ' ' + response.statusText);
                if (response.status == 401) {
                    setToken(null);
                    location.href = "";
                }
                console.log(response);
            }

        }
    };

    /**
     * Cmabia la etapa del Lead
     * @param {any} event
     */
    const CambiarEtapa = async (event) => {
        console.log("evento de etapa: ", event)

        var detalles = '';

        if (event.target.value == 5) {
            //muestra el modal para mandar un comentario
            setMotivoNoInteresado("");
            setShowEtapaNoInteresadoModal(true);
            setIdLead(detallesLead.idLead);
            setHabilitarBtnGuardar(true);
            
        } else if (event.target.value == 4 && detallesLead.idClienteAkasia == null) {
          
            setvNombreEtapa(detallesLead.vNombreEtapa);
            setIdLead(detallesLead.idLead);
            setShowEtapaClienteGanadoModal(true); 
        }
        else {//si no es "cliente ganado ni "no interesado"
            var jDataSend = {}

            jDataSend.idEtapa = event.target.value;
            jDataSend.idLead = detallesLead.idLead;

            const response = await fetch("/api/leads/changeEtapaLead/",
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jDataSend)
                }).catch((error) => {
                    setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                    setShowMensajeError(true);
                    console.log('Hubo un problema con la peticion Fetch:' + error);
                });

            if (response.ok) {

                console.log("==== Change Lead Etapa ====");
                console.log("response en etapa: ", response);
                const data = await response.json();
                console.log("data en etapa: ", data);

                if (data.iResponseType == 1) {

                    detalles = JSON.parse(data.detalles);
                    console.log("Detalles de etapa: ", detalles);

                    GuardarComentario("Modificó etapa de <b>" + detallesLead.vNombreEtapa + "</b> a <b>" + detalles[0].vNombreEtapa + "</b>", "2", detallesLead.idLead);

                    if (contactos !== null && typeof contactos !== 'undefined') {

                        var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                        if (currentLeadIndex == -1) {

                            var detalleslead_Copy = detallesLead;
                            detalleslead_Copy.idEtapa = detalles[0].idEtapa;
                            detalleslead_Copy.vNombreEtapa = detalles[0].vNombreEtapa;

                            setContactos([detalleslead_Copy, ...contactos]);

                        }
                        else {
                            contactos[currentLeadIndex].iDaysDiff = 0;
                            contactos[currentLeadIndex].idEtapa = detalles[0].idEtapa;
                            contactos[currentLeadIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                        }
                    }

                    if (leadsVinculados !== null && typeof leadsVinculados !== 'undefined') {

                        var currentLeadEditadoIndex = leadsVinculados.findIndex((lead) => lead.idLead === detallesLead.idLead);

                        leadsVinculados[currentLeadEditadoIndex].idEtapa = detalles[0].idEtapa;
                        leadsVinculados[currentLeadEditadoIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                        
                    }

                    //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead.
                    if (idUsuario != detallesLead.idUsuario) {

                        //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                        var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                        guardarNotificacion("Etapa de lead cambiada",
                            "<b> " + nombreCompleto + "</b> modificó la etapa del lead <b>#" + detallesLead.idLead + "</b> a: <i> '" + detalles[0].vNombreEtapa.substring(0, 7) + "...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2
                        );
                    }

                    setDetallesLead({
                        ...detallesLead,
                        idEtapa: detalles[0].idEtapa,
                        vNombreEtapa: detalles[0].vNombreEtapa,
                        iDaysDiff: 0
                    });

                    setEditarEtapaModal(false);
                    setMensajeInformativo("Cambio de etapa exitoso");
                    setShowMensajeInformativo(true);

                }
                else {
                    setShowMensajeError(true);
                    setMensajeError(data.vMessage);
                }

            }
            else {
                setShowMensajeError(true);
                setMensajeError(response.status + ' ' + response.statusText);
                if (response.status == 401) {
                    setToken(null);
                    location.href = "";
                }
                console.log(response);
            }
        }
    };

    /**
     * función para editar un evento y mostrar el modal de editar evento
     */
    const editarEventos = async () => {

        //bandera para editar evento
        setEditarEvento(true);

        //llenar form data
        setFormDataEvent({
            ...formDataEvent,
            vTituloEvento: eventosActivos[0].vTituloEvento,
            vDescripcionEvento: eventosActivos[0].vDescripcionEvento,
            dFechaEvento: new Date(eventosActivos[0].dFechaEvento),
            idLeadEvento: eventosActivos[0].idLeadEvento
        });

        //mostrar modal
        setShowAgregarEvento(true);

    };

    /**
     * Vincula leads
     */
    const vincularLead = async () => {

        setProcesandoVincular(true);
        var jDataSend = {};
        jDataSend.idLeadVincular = idLeadVincular;//numero de lead que el usuario escribe
        jDataSend.idLead = detallesLead.idLead;//id del lead abierto

        const response = await fetch("/api/leads/vincularLead/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== VINCULAR LEAD ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                //actualizar la lista de leads vinculados cambiando un estado, sin recargar pagina
                var ObjDetalles_ = (detalles['table2']);
                console.log("ObjDetalles_:", ObjDetalles_);

                var ObjDetalles = ObjDetalles_[0];

                console.log("ObjDetalles:", ObjDetalles);

                var ObjLeads = JSON.parse(ObjDetalles.vJsonLeadsVinculados);
                console.log("ObjLeads: ", ObjLeads);

                const newArray = ObjLeads.filter(e => e.idLead !== detallesLead.idLead);
                console.log("newArray: ", newArray);

                setListaJsonLeadsVinculados(newArray);

                if (leadsVinculados !== null && typeof leadsVinculados !== 'undefined') {
                    
                    setLeadsVinculados(detalles['table3']);
                }

                setHabilitarVinculacion(0);

                GuardarComentario("<b class='badge badge-soft-info' >Se vinculo al Lead #" + idLeadVincular + "</b>", 2, detallesLead.idLead);
                GuardarComentario("<b class='badge badge-soft-info' >Se vinculo al Lead #" + detallesLead.idLead + "</b>", 2, idLeadVincular);

                setProcesandoVincular(false);

                setIdLeadVincular("");

                if (contactos !== null && typeof contactos !== 'undefined') {

                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {
                        var detallesLeadCopy = detallesLead;
                        detallesLeadCopy.iDaysDiff = 0;

                        setContactos([detallesLeadCopy, ...contactos]);
                    } else {
                        contactos[currentLeadIndex].iDaysDiff = 0;
                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead vincula otro lead.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);

                    guardarNotificacion("Leads vinculados",
                        "<b> " + nombreCompleto + "</b> vinculó los leads <b>#" + detallesLead.idLead + "</b> y <b> #" + idLeadVincular + "</b>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );
                }

                setDetallesLead({
                    ...detallesLead,
                    iDaysDiff: 0,
                    idClienteAkasia: ObjDetalles.idClienteAkasia,
                    vNombreClienteAkasia: ObjDetalles.vNombreClienteAkasia
                });

            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setProcesandoVincular(false);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            setProcesandoVincular(false);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    /**
     * Desvincula leads
     */
    const desvincularLead = async (idLeadDesvincular) => {

        setProcesandoDesvincular(true);
        var jDataSend = {};
        jDataSend.idLeadDesvincular = idLeadDesvincular;//numero de lead que el usuario escribe en el
        jDataSend.idLead = detallesLead.idLead;//id del lead abierto

        const response = await fetch("/api/leads/desvincularLead/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== DESVINCULAR LEAD ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var ObjLeadsVinculadosModal = (JSON.parse(detalles['table2'][0]['vJsonLeadsVinculados']));
                console.log("ObjLeadsVinculadosModal:", ObjLeadsVinculadosModal);

                var ObjLeadsVinculados = (detalles['table3']);
                console.log("ObjLeadsVinculados:", ObjLeadsVinculados);


                const newArray = ObjLeadsVinculadosModal.filter(e => e.idLead !== detallesLead.idLead);
                setListaJsonLeadsVinculados(newArray);
                setHabilitarVinculacion(0);

                if (leadsVinculados !== null && typeof leadsVinculados !== 'undefined') {
                    setLeadsVinculados(ObjLeadsVinculados);
                }

                GuardarComentario("<b class='badge badge-soft-danger' >Se desvinculo al Lead #" + detallesLead.idLead + "</b>", 2, idLeadDesvincular);
                GuardarComentario("<b class='badge badge-soft-danger' >Se desvinculo al Lead #" + idLeadDesvincular + "</b>", 2, detallesLead.idLead);

                setProcesandoDesvincular(false);

                if (contactos !== null && typeof contactos !== 'undefined') {

                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {
                        var detallesLeadCopy = detallesLead;
                        detallesLeadCopy.iDaysDiff = 0;

                        setContactos([detallesLeadCopy, ...contactos]);
                    } else {
                        contactos[currentLeadIndex].iDaysDiff = 0;
                    }
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead desvincula otro lead.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Leads desvinculados",
                        "<b> " + nombreCompleto + "</b> desvinculó los leads <b>#" + idLeadDesvincular + "</b> y <b> #" + detallesLead.idLead + "</b>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );
                }

                setDetallesLead({
                    ...detallesLead,
                    iDaysDiff: 0
                });


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
                setProcesandoDesvincular(false);

            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);
            setProcesandoDesvincular(false);

            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    /**
     * Desvincula un cliente de un lead
     */
    const desvincularLeadCliente = async () => {

        setProcesandoDesvincularLeadCliente(true);

        var objDataSend = {};
        objDataSend.idLead = detallesLead.idLead;
        objDataSend.idClienteAkasia = detallesLead.idClienteAkasia;

        console.log(objDataSend);
        var jDataSend = JSON.stringify(objDataSend);

        const response = await fetch("/api/leads/desvincularLeadCliente/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: jDataSend
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== DESVINCULAR CLIENTE DE UN LEAD ====");
            console.log("response: ", response);

            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);

                console.log("detalles: ", detalles);

                setProcesandoDesvincularLeadCliente(false);

                if (leadsVinculados !== null && typeof leadsVinculados !== 'undefined') {
                    if (detalles == null) {
                        setLeadsVinculados([]);
                    } else {
                        setLeadsVinculados(detalles['table2']);
                    }
                }

                if (contactos !== null && typeof contactos !== 'undefined') {

                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);

                    if (currentLeadIndex == -1) {
                        var detallesLeadCopy = detallesLead;
                        detallesLeadCopy.iDaysDiff = 0;
                        detallesLeadCopy.idClienteAkasia = null;
                        detallesLeadCopy.vNombreClienteAkasia = null;

                        setContactos([detallesLeadCopy, ...contactos]);
                    } else {
                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].idClienteAkasia = null;
                        contactos[currentLeadIndex].vNombreClienteAkasia = null;;
                    }

                }

                if (listaJsonLeadsVinculados.length > 0) {
                    listaJsonLeadsVinculados.map((lead) => {

                        GuardarComentario("<b class='badge badge-soft-danger' >Se desvinculo al cliente</b> <b>" + detallesLead.vNombreClienteAkasia + "</b>", 2, lead.idLead);

                        if (contactos !== null && contactos !== undefined) {
                            currentLeadIndex = contactos.findIndex((l) => l.idLead === lead.idLead);

                            console.log(currentLeadIndex);
                            if (currentLeadIndex > -1) {//si el lead vinculado esta en la lista de contactos
                                contactos[currentLeadIndex].iDaysDiff = 0;
                            }
                        }

                        
                    })
                }

                //Guarda una notificación si alguien que no es el vendedor de este lead desvincula el cliente.
                if (idUsuario != detallesLead.idUsuario) {

                    //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Cliente desvinculado",
                        "<b> " + nombreCompleto + "</b> desvinculó el lead <b>#" + detallesLead.idLead + "</b> con el cliente : <i> '" + detallesLead.vNombreClienteAkasia.substring(0, 3) + "...'</i>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );
                }
                
                GuardarComentario("<b class='badge badge-soft-danger' >Se desvinculo al cliente</b> <b>" + detallesLead.vNombreClienteAkasia + "</b>", 2, detallesLead.idLead);

                detallesLead.idClienteAkasia = null;
                detallesLead.vNombreClienteAkasia = null;

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
                setProcesandoDesvincularLeadCliente(false);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            setProcesandoDesvincularLeadCliente(false);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    };

    /**
     * asigna el lead a otro vendedor
     */
    const cambiarVendedorLead = async () => {

        setHabilitarBtnAsignarLead(false);
        setHabilitarBtnGuardarComentario(false);

        var jDataSend = {};

        jDataSend.idUsuario = idUsuario;
        jDataSend.idLead = detallesLead.idLead;

        const response = await fetch("/api/leads/cambiarVendedorLead/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== CAMBIAR EL VENDEDOR DEL LEAD ====");
            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);

            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                await GuardarComentario("<b class='badge badge-soft-success' >Cambio  de vendedor</b> <b>" + detallesLead.vNombreUsuario + "</b> a <b> " + detalles.vNombreUsuario + " </b>", 2, detallesLead.idLead);
                await GuardarComentario(comentarioCambioVendedor, 1, detallesLead.idLead);
                detallesLead.bRelease = false;

                
                //Guarda una notificación si alguien que no es el vendedor de este lead desvincula el cliente.
                if (idUsuario != detallesLead.idUsuario) {

                    var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                    guardarNotificacion("Asignación de lead",
                        "<strong>" + nombreCompleto + "</strong> se asignó el lead <strong>#" + detallesLead.idLead + "</strong>",
                        detallesLead.idUsuario,
                        objToActionString,
                        2
                    );

                }
                    

                if (contactos !== null && typeof contactos !== 'undefined') {
                    var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === detallesLead.idLead);
                    var newContactos;

                    if (currentLeadIndex > -1) {

                        if (vendedor == 0 || vendedor == detalles.idUsuario) {

                            contactos[currentLeadIndex].iDaysDiff = 0;
                            contactos[currentLeadIndex].vNombreUsuario = detalles.vNombreUsuario;
                            contactos[currentLeadIndex].idUsuario = detalles.idUsuario;
                            contactos[currentLeadIndex].vUsuario = detalles.vUsuario;

                        } else {

                            newContactos = [
                                ...contactos.slice(0, currentLeadIndex),
                                ...contactos.slice(currentLeadIndex + 1)
                            ];

                            setContactos(newContactos);

                        }
                    }
                }

                //Si el lead que se esta asignando tiene leads vinculados estos tambien se quitaran de la lista de contactos
                if (listaJsonLeadsVinculados.length > 0) {
                    listaJsonLeadsVinculados.map((lead) => {

                        GuardarComentario("<b class='badge badge-soft-success' >Cambio  de vendedor</b> <b>" + detallesLead.vNombreUsuario + "</b> a <b> " + detalles.vNombreUsuario + " </b>", 2, lead.idLead);
                        GuardarComentario(comentarioCambioVendedor, 1, lead.idLead);

                        if (contactos !== null && contactos !== undefined) {
                            var currentLeadVinculadoIndex = newContactos.findIndex((l) => lead.idLead === l.idLead);

                            if (currentLeadVinculadoIndex > -1) {

                                const currentLeadVinculadoModificado = {
                                    ...contactos[currentLeadVinculadoIndex],
                                    vNombreUsuario: detalles.vNombreUsuario,
                                    idUsuario: detalles.idUsuario,
                                    vUsuario: detalles.vUsuario,
                                    iDaysDiff: 0
                                };


                                if (vendedor == 0 || vendedor == detalles.idUsuario) {
                                    console.log("el vendedor eres tu")
                                    newContactos = [
                                        ...newContactos.slice(0, currentLeadVinculadoIndex),
                                        currentLeadVinculadoModificado,
                                        ...newContactos.slice(currentLeadVinculadoIndex + 1)
                                    ];

                                    contactos[currentLeadVinculadoIndex].idaysdiff = 0;
                                    contactos[currentLeadVinculadoIndex].vnombreusuario = detalles.vNombreUsuario;
                                    contactos[currentLeadVinculadoIndex].idusuario = detalles.idUsuario;
                                    contactos[currentLeadVinculadoIndex].vUsuario = detalles.vUsuario;

                                } else {
                                    console.log("el vendedor no eres tu")
                                    newContactos = [
                                        ...newContactos.slice(0, currentLeadVinculadoIndex),
                                        ...newContactos.slice(currentLeadVinculadoIndex + 1)
                                    ];
                                    console.log("nuevos contactos: ", newContactos);

                                    setContactos(newContactos);
                                }
                            }
                        }

                    })
                }

                setDetallesLead({
                    ...detallesLead,
                    vNombreUsuario: detalles.vNombreUsuario,
                    idUsuario: detalles.idUsuario,
                    vUsuario: detalles.vUsuario,
                    iDaysDiff: 0
                });

                //si el lead esta en la lista de leads liberaods se limpia, tanto de la completa como de la que solo tiene 4 leads
                if (listaLeadsLiberados !== null && listaLeadsLiberados !== undefined) {
                    var currentLeadLiberadoIndex = listaLeadsLiberados.findIndex((l) => l.idLead === detallesLead.idLead);

                    if (currentLeadLiberadoIndex > -1) {

                        const newLeadsLiberados = [
                            ...listaLeadsLiberados.slice(0, currentLeadLiberadoIndex),
                            ...listaLeadsLiberados.slice(currentLeadLiberadoIndex + 1)
                        ];

                        setListaLeadsLiberados(newLeadsLiberados);
                    }
                }
                
                if (lista3LeadsLiberados !== null && lista3LeadsLiberados !== undefined) {
                    currentLeadLiberadoIndex = lista3LeadsLiberados.findIndex((l) => l.idLead === detallesLead.idLead);

                    if (currentLeadLiberadoIndex > -1) {

                        if (typeof mostrarLeads === 'function' && Array.isArray(Filter_Tags) && typeof filtroEventosVencidos === 'boolean' ) {
                            mostrarLeads(Filter_Tags, filtroEventosVencidos);
                        }
                    }
                }

                setMensajeInformativo("Asignación de vendedor exitoso");
                setShowMensajeInformativo(true);
                setHabilitarBtnAsignarLead(true);
                setHabilitarBtnGuardarComentario(true);
                setShowModalComentarioCambioVendedor(false);
                setComentarioCambioVendedor('');

            } else {
                setHabilitarBtnAsignarLead(true);
                setHabilitarBtnGuardarComentario(true);
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }
        }
        else {
            setHabilitarBtnAsignarLead(true);
            setHabilitarBtnGuardarComentario(true);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }

    }

    /**
     * Cambia la etapa a no interesado
     * @param {any} idLead
     */
    const etapaNoInteresado = async (idLead) => {

        if (motivoNoInteresado.length > 5) {

            setHabilitarBtnGuardar(false);

            var jDataSend = {};

            jDataSend.idEtapa = 5;
            jDataSend.idLead = idLead;

            const response = await fetch("/api/leads/changeEtapaLead/",
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jDataSend)
                }).catch((error) => {

                    setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                    setShowMensajeError(true);
                    console.log('Hubo un problema con la peticion Fetch:' + error);
                });

            if (response.ok) {

                console.log("==== CHANGE ETAPA LEAD ====");
                console.log(response);
                const data = await response.json();
                console.log(data);

                if (data.iResponseType == 1) {

                    var detalles = JSON.parse(data.detalles);
                    console.log(detalles);

                    GuardarComentario("<b class='badge badge-soft-danger'>Lead no interesado </b> " + motivoNoInteresado, 2, idLead);

                    //Guarda una notificación si alguien que no es el vendedor de este lead cambia la etapa del lead a no interesado.
                    if (idUsuario != detallesLead.idUsuario) {

                        //este objeto nos dice la accion que se realizara cuando se le de click a la notifiacion
                        var objToActionString = crearObjAction(1, '', 1, detallesLead.idLead);
                        guardarNotificacion("Etapa de lead cambiada",
                            "<b> " + nombreCompleto + "</b> modificó la etapa del lead <b>#" + detallesLead.idLead + "</b> a: <i> 'No intere...'</i>",
                            detallesLead.idUsuario,
                            objToActionString,
                            2
                        );
                    }

                    setDetallesLead({
                        ...detallesLead,
                        idEtapa: detalles[0].idEtapa,
                        vNombreEtapa: detalles[0].vNombreEtapa,
                        iDaysDiff: 0
                    });

                    if (contactos !== null && typeof contactos !== 'undefined') {
                        var currentLeadIndex = contactos.findIndex((lead) => lead.idLead === idLead);

                        console.log("currentLeadIndex: ", currentLeadIndex);

                        contactos[currentLeadIndex].iDaysDiff = 0;
                        contactos[currentLeadIndex].vNombreEtapa = detalles[0].vNombreEtapa;
                        contactos[currentLeadIndex].idEtapa = detalles[0].idEtapa;
                    }

                    if (leadsVinculados !== null && typeof leadsVinculados !== 'undefined') {

                        var currentLeadEditadoIndex = leadsVinculados.findIndex((lead) => lead.idLead === detallesLead.idLead);

                        leadsVinculados[currentLeadEditadoIndex].idEtapa = detalles[0].idEtapa;
                        leadsVinculados[currentLeadEditadoIndex].vNombreEtapa = detalles[0].vNombreEtapa;

                    }

                    //setEditarEtapa("");
                    setMensajeInformativo("Cambio de etapa exitoso");
                    setShowMensajeInformativo(true);
                    setShowEtapaNoInteresadoModal(false);
                    setEditarEtapaModal(false);


                } else {
                    setHabilitarBtnGuardar(true);
                    setShowMensajeError(true);
                    setMensajeError(data.vMessage);
                }

            }
            else {
                setHabilitarBtnGuardar(true);
                setShowMensajeError(true);
                setMensajeError(response.status + ' ' + response.statusText);
                if (response.status == 401) {
                    setToken(null);
                    location.href = "";
                }
                console.log(response);
            }
        } else {

            setMensajeError('Es obligatorio poner un motivo de no interesado');
            setShowMensajeError(true);

        }

    } 

    /**
     * Guarda una notificación
     */
    const guardarNotificacion = async (vTitulo, vCuerpo, idUsuarioTarget, objToAction, idTipoNotificacion) => {

        var jDataSend = {};
        jDataSend.vTitulo = vTitulo;
        jDataSend.vCuerpo = vCuerpo;
        jDataSend.idUsuarioTarget = idUsuarioTarget;
        jDataSend.objToAction = objToAction;
        jDataSend.idTipoNotificacion = idTipoNotificacion;

        const response = await fetch("/api/leads/guardarNotificacion/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACIÓN GUARDADA ====");

            console.log("response: ", response);
            const data = await response.json();
            console.log("data: ", data);
            console.log("objToAction: ", objToAction);
            if (data.iResponseType == 1) {

                var detalles = JSON.parse(data.detalles);
                console.log("detalles: ", detalles);

                var notificacionesList = detalles.table2;
                console.log("notificacionesList: ", notificacionesList);

                notificacionesList.map((objNotify) => {
                    sendPushNotificationRealTime(objNotify.vToken, objNotify.vCuerpo);
                })


            } else {

                setMensajeError(data.vMessage);
                setShowMensajeError(true);
            }

        }
        else {

            setMensajeError(response.status + ' ' + response.statusText);
            setShowMensajeError(true);

            console.log(response);
        }
    };

    /**
     * Envia una notificacion push al usuario con el token que recibe como parametro
     * @param {any} token
     * @param {any} title
     * @param {any} body
     */
    const sendPushNotificationRealTime = async (vToken, vNotificacion) => {

        const responseTokenFCM = await fetch("/api/account/generateToken/",
        {
            method: 'POST',
            headers:
            {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {

            console.log('Hubo un problema con la peticion Fetch del token:' + error);
        });


        //console.log("TOKEN TOKEN:", token);
        console.log("responseTokenFCM:", responseTokenFCM);

        if (responseTokenFCM.ok) {
            const dataFCM = await responseTokenFCM.json();
            console.log("data", dataFCM);

            var tokenFCM = dataFCM.detalles;

            try {
                const response = await fetch('https://fcm.googleapis.com/v1/projects/akasia-admin-panel/messages:send', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + tokenFCM,
                        'Content-Type': 'application/json'
                    },
                    //body: JSON.stringify({
                    //    to: vToken,
                    //    data: { vNotificacion: vNotificacion }
                    //})
                    body: JSON.stringify({
                        message: {
                            token: vToken,
                            data: { vNotificacion: vNotificacion }
                        }
                    })
                });

                const dataNtf = await response.json();
                console.log('Notificación enviada:', dataNtf);

            } catch (error) {
                console.error('Error al enviar la notificación:', error.message);
            }
        }
        
        //POST 
        //https://fcm.googleapis.com/fcm/send
        
    };

    /**
     * Crea un objeto de acción que especifica la acción a realizar al hacer clic en una notificación,
     * ya sea abrir un modal o redireccionar a otra página.
     * @param {any} TypeAction
     * @param {any} vURL
     * @param {any} idModalType
     * @param {any} idLead
     * @returns
     */
    const crearObjAction = (TypeAction, vURL, idModalType, idLead, ) => {
        var objToAction = {};
        var objDetalles = {};
        var objData = {};

        objData.idLead = idLead;

        objDetalles.vUrl = vURL;
        objDetalles.idModalType = idModalType;
        objDetalles.data = objData

        objToAction.TypeAction = TypeAction;
        objToAction.detalles = objDetalles;

        var objToActionString = JSON.stringify(objToAction);
        console.log("objToAction: ", objToActionString);
        return objToActionString;
    };

    /**
     * Guarda comentario al lead
     * @param {any} e
     * @returns
     */
    const submitComment = async (e) => {
        e.preventDefault();

        if (comentario == '') {
            return;
        }
        
        GuardarComentario(comentario, "1", detallesLead.idLead);

        if (idUsuario != detallesLead.idUsuario) {
            var objToAction = {};
            var objDetalles = {};
            var objData = {};

            objData.idLead = detallesLead.idLead;

            objDetalles.vUrl = '';
            objDetalles.idModalType = 1;
            objDetalles.data = objData

            objToAction.TypeAction = 1;
            objToAction.detalles = objDetalles;

            var objToActionString = JSON.stringify(objToAction);
            console.log("objToActionComentario: ", objToActionString);
            guardarNotificacion("Se hizo un comentario en un lead",
                "<b> " + nombreCompleto + "</b> hizo un comentario en el lead <b>#" + detallesLead.idLead + "</b> : <i> '" + comentario.substring(0, 3) + "...'</i>",
                detallesLead.idUsuario,
                objToActionString,
                2
            );
        }

    };


    const handleChange = (name, value) => {
        setFormDataEvent({
            ...formDataEvent,
            [name]: value
        });
    };

    /**
     * Si se cierra el modal de detalles lead se setean las variables a vacias
     */
    const closeModalLead = () => {

        setShowModal(false);
        setDetallesLead([]);
        setEditarEtapaModal(false);
        setEditarGiroComercial(false);
        setEditarPropuestaEconomica(false);
        setHabilitarVinculacion(0);
        setListaJsonLeadsVinculados([]);

        setModal_JsonTags([]);
        setModal_valueTagSelected(-1);
        setModal_BitAddTag(false);
        setModal_Moneda("");

        setComentario("");

        setIdLeadVincular("");
        setHabilitarBtnContinuarLiberarLead(true);
        setProcesandoDesvincular(false);
        setProcesandoVincular(false);
        setHabilitarBtnGuardarComentario(true);
        setProcesandoDesvincularLeadCliente(false);
        setIdLead(0);
        //setModalAbierto("");

    };

    return (

        <>
            {
               
                showModal &&
                <Modal
                    show={showModal}
                    onHide={
                        () => {
                            closeModalLead();
                        }
                    }
                    size="lg"
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id={"modalSeguimientoLead"}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {cargandoModal ?
                                <Skeleton />
                                :
                                <span className="col-12">
                                    <Row>
                                        <Col className="col-12">
                                            Seguimiento de Contacto / Lead #{detallesLead.idLead}
                                        </Col>
                                        <Col className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 fs-1 me-1">
                                            <SoftBadge
                                                bg={
                                                    detallesLead.iDaysDiff >= 0 && detallesLead.iDaysDiff < 3 ? "success" :
                                                        detallesLead.iDaysDiff >= 3 && detallesLead.iDaysDiff < 5 ? "warning" : "danger"}
                                            >
                                                {detallesLead.iDaysDiff + " días sin actualizaciones"}
                                            </SoftBadge>

                                        </Col>
                                        <Col className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 fs-1 p-1">

                                            {
                                                !detallesLead.bRelease && detallesLead.idUsuario == idUsuario ?//no esta liberado el lead y es mio
                                                    habilitarBtnLiberarLead ?
                                                        detallesLead.idEtapa == 4 ? //si la etapa es "cliente ganado" no debe mostrar el boton asignarme este lead
                                                            <>
                                                                {/* Mostrar el boton 'Liberar lead' */}
                                                                <button
                                                                    onClick={() => {
                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                            setShowModalLiberarLeads(true)
                                                                            :
                                                                            liberarLead()
                                                                    }
                                                                    }
                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                    Liberar Lead
                                                                </button>

                                                            </>
                                                            :
                                                            <>
                                                                {/* Mostrar el boton 'Liberar lead' */}
                                                                <button
                                                                    onClick={() => {
                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                            setShowModalLiberarLeads(true)
                                                                            :
                                                                            liberarLead()
                                                                    }
                                                                    }
                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                    Liberar Lead
                                                                </button>
                                                            </>
                                                        :
                                                        <>
                                                            {/* mostrar boton como "cargando" */}
                                                            <button className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                <Spinner className="text-inline" animation="border" size="sm" variant="ligth" />&nbsp; Cargando...
                                                            </button>
                                                        </>
                                                    :
                                                    detallesLead.bRelease ?
                                                        detallesLead.idEtapa == 4 ? //si la etapa es "cliente ganado" no debe mostrar el boton asignarme este lead
                                                            <>

                                                                {/* Mostrar el boton 'Asignarme este lead' */}
                                                                <button
                                                                    onClick={() => {
                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                            setShowModalAsignarmeLeadsVinculados(true)
                                                                            :
                                                                            setShowModalComentarioCambioVendedor(true)
                                                                    }
                                                                    }
                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                    Asignarme este Lead
                                                                </button>

                                                            </>
                                                            :
                                                            <>
                                                                {/* Mostrar el boton 'Asignarme este lead' */}
                                                                <button
                                                                    onClick={() => {
                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                            setShowModalAsignarmeLeadsVinculados(true)
                                                                            :
                                                                            setShowModalComentarioCambioVendedor(true)
                                                                    }
                                                                    }
                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                    Asignarme este Lead
                                                                </button>
                                                            </>
                                                        :
                                                        <>
                                                            {
                                                                detallesLead.iDaysDiffOwner >= 5 ?
                                                                    habilitarBtnAsignarLead ?
                                                                        detallesLead.idEtapa == 4 ? //si la etapa es "cliente ganado" no debe mostrar el boton asignarme este lead
                                                                            <>
                                                                                {/* Mostrar el boton 'Asignarme este lead' */ console.log("idEtapa: ", detallesLead.idEtapa)}
                                                                                <button
                                                                                    onClick={() => {
                                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                                            setShowModalAsignarmeLeadsVinculados(true)
                                                                                            :
                                                                                            setShowModalComentarioCambioVendedor(true)
                                                                                    }
                                                                                    }
                                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                                    Asignarme este Lead
                                                                                </button>

                                                                            </>
                                                                            :
                                                                            <>
                                                                                {/* Mostrar el boton 'Asignarme este lead' */ console.log("idEtapa: ", detallesLead.idEtapa)}
                                                                                <button
                                                                                    onClick={() => {
                                                                                        listaJsonLeadsVinculados.length >= 1 ?
                                                                                            setShowModalAsignarmeLeadsVinculados(true)
                                                                                            :
                                                                                            setShowModalComentarioCambioVendedor(true)
                                                                                    }
                                                                                    }
                                                                                    className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                                    Asignarme este Lead
                                                                                </button>
                                                                            </>
                                                                        :
                                                                        <>
                                                                            {/* mostrar boton como "cargando" */}
                                                                            <button className="d-sm-block me-2 btn btn-primary btn-sm">
                                                                                <Spinner className="text-inline" animation="border" size="sm" variant="ligth" />&nbsp; Cargando...
                                                                            </button>
                                                                        </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>

                                            }

                                        </Col>
                                        <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 fs-1 p-1 ms-3 ">
                                            {
                                                detallesLead.idClienteAkasia == null || detallesLead.idClienteAkasia == '' ?
                                                    <>
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Vincular cliente</Tooltip>}>
                                                                <span onClick={() => { setIdLead(detallesLead.idLead); setShowEtapaClienteGanadoModal(true); }} style={{ cursor: 'pointer' }}>
                                                                <FontAwesomeIcon className="me-1" icon={icon({ name: 'user', style: "solid" })} style={{ color: "#000000", }} size="xs" />
                                                                <span className="text-muted fs--1 "> Click para vincular cliente</span>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </>
                                                    :
                                                    <>
                                                        <Link to={"/clientes/detalle/" + detallesLead.idClienteAkasia}>

                                                            <span className="fs-0">
                                                                {detallesLead.vNombreClienteAkasia == ' ' ?
                                                                    <>
                                                                        Cliente {detallesLead.idClienteAkasia}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Cliente {detallesLead.idClienteAkasia} | {detallesLead.vNombreClienteAkasia.substring(0, 14)}
                                                                    </>
                                                                }
                                                            </span>

                                                        </Link>

                                                        <OverlayTrigger placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    <strong>Editar Cliente</strong>
                                                                </Tooltip>
                                                            }>
                                                            <span>
                                                                    &nbsp;<img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => { setIdLead(detallesLead.idLead); setShowEtapaClienteGanadoModal(true); }}></img>
                                                            </span>
                                                        </OverlayTrigger>
                                                        {
                                                            procesandoDesvincularLeadCliente ?
                                                                <>
                                                                    <FontAwesomeIcon className="ms-2"

                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                        style={{ color: "#9da9bb", }}
                                                                        disabled />
                                                                </>
                                                                :
                                                                <>

                                                                    <OverlayTrigger placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                <strong>Desvincular Cliente</strong>
                                                                            </Tooltip>
                                                                        }>
                                                                        <FontAwesomeIcon className="ms-2"
                                                                            onClick={
                                                                                () =>
                                                                                    desvincularLeadCliente()
                                                                            }
                                                                            icon={icon({ name: 'trash-can', style: "solid" })}
                                                                            style={{ color: "#c32847", cursor: 'pointer' }}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </>

                                                        }
                                                    </>
                                            }
                                        </Col>

                                    </Row>

                                </span>
                            }

                        </Modal.Title>
                            <FalconCloseButton className="btn btn-circle btn-sm transition-base p-0" id={"cerrarModal"}
                            onClick={
                                () => {
                                    closeModalLead();
                                }
                            }
                        />

                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={7} xs={12}>

                                {/*Nombre del Lead*/}
                                {editarNombreLead ?
                                    <>
                                        <InputGroup className="fs--1">
                                            <img width="25" src="/img/cliente48.png"></img>&nbsp;
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                defaultValue={Modal_NombreLead}
                                                disabled={cargandoModal ? true : false}
                                                autoComplete="off"
                                                onChange={
                                                    (event) => {
                                                        setModal_NombreLead(event.target.value);
                                                    }
                                                }
                                                onKeyDown={
                                                    (e) => {

                                                        if (e.key === "Enter") {
                                                            actualizarNombreLead();
                                                        }
                                                    }
                                                }
                                                id="FormNombreLead"
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={
                                                    () => {

                                                        actualizarNombreLead();

                                                    }
                                                }
                                            >
                                                <img src="/img/check25.png" width="20"></img>
                                            </Button>
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={
                                                    () => {
                                                        setEditarNombreLead(false)
                                                    }
                                                }
                                            >
                                                ❌
                                            </Button>
                                        </InputGroup>
                                    </> :
                                    cargandoModal ?
                                        <Skeleton />
                                        :
                                        <>
                                            <img width="25" src="/img/cliente48.png"></img>&nbsp;
                                            <strong className="fs-2">{detallesLead.vNombreLead} </strong>
                                            <OverlayTrigger placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        <strong>Editar Nombre Lead</strong>
                                                    </Tooltip>
                                                }>
                                                <span>
                                                    &nbsp;<img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => setEditarNombreLead(true)}></img>
                                                </span>
                                            </OverlayTrigger>
                                        </>
                                }

                                {/*WhatsApp*/}
                                <br></br>
                                {cargandoModal ?
                                    <Skeleton />
                                    :

                                        detallesLead.vTelefonoLead != undefined && (
                                            <>
                                                {detallesLead.vTelefonoLead.includes('+52') && (
                                                    <> <img title="M&eacute;xico" src="/img/bandera-mexico-48.png" width="20" alt="Bandera de México" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+1809') && (
                                                    <> <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20"  /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+1829') && (
                                                    <> <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+1849') && (
                                                    <> <img title="Rep&uacute;blica Dominicana" src="/img/bandera-dominican-republic-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+502') && (
                                                    <> <img title="Guatemala" src="/img/bandera-guatemala-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+503') && (
                                                    <> <img title="El Salvador" src="/img/bandera-el-salvador-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+504') && (
                                                    <> <img title="Honduras" src="/img/bandera-honduras-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+505') && (
                                                    <> <img title="Nicaragua" src="/img/bandera-nicaragua-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+506') && (
                                                    <> <img title="Costa Rica" src="/img/bandera-costa-rica-48.png" width="20"  /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+507') && (
                                                    <> <img title="Panamá" src="/img/bandera-panama-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+51') && (
                                                    <> <img title="Perú" src="/img/bandera-peru-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+53') && (
                                                    <> <img title="Cuba" src="/img/bandera-cuba-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+54') && (
                                                    <> <img title="Argentina" src="/img/bandera-argentina-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+56') && (
                                                    <> <img title="Chile" src="/img/bandera-chile-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+57') && (
                                                    <> <img title="Colombia" src="/img/bandera-colombia-48.png" width="20"  /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+58') && (
                                                    <> <img title="Venezuela" src="/img/bandera-venezuela-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+591') && (
                                                    <> <img title="Bolivia" src="/img/bandera-bolivia-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+593') && (
                                                    <> <img title="Ecuador" src="/img/bandera-ecuador-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+595') && (
                                                    <> <img title="Paraguay" src="/img/bandera-paraguay-circular-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+598') && (
                                                    <> <img title="Uruguay" src="/img/bandera-uruguay-48.png" width="20" /> </>
                                                )}
                                                {detallesLead.vTelefonoLead.includes('+1787') && (
                                                    <> <img title="Puerto Rico" src="/img/bandera-puerto-rico-48.png" width="20" /> </>
                                                )}

                                                {detallesLead.vTelefonoLead.includes('+1939') && (
                                                    <> <img title="Puerto Rico" src="/img/bandera-puerto-rico-48.png" width="20" /> </>
                                                )}

                                                <a className="text-900 fs-2" href={`https://wa.me/${detallesLead.vTelefonoLead}`} target="_blank" rel="noreferrer">
                                                    <img width="30" src="/img/whatsapp40.png" alt="Icono de WhatsApp" />{detallesLead.vTelefonoLead}
                                                </a>
                                            </>
                                        )
                                        
                                }
                                {/*Giro comercial*/}
                                <br></br>
                                {editarGiroComercial ?
                                    <>
                                        <span>
                                            <InputGroup className="fs--1">
                                                <img width="30" src="/img/sucursal20.png"></img>&nbsp;

                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    defaultValue={Modal_GiroComercial}
                                                    disabled={cargandoModal ? true : false}
                                                    autoComplete="off"
                                                    onChange={
                                                        (event) => {
                                                            setModal_GiroComercial(event.target.value);
                                                        }
                                                    }
                                                    onBlur={
                                                        () => {
                                                            //console.log('Triggered because this input lost focus');
                                                            //actualizarGiroComercial();
                                                        }
                                                    }
                                                    onKeyDown={
                                                        (e) => {

                                                            if (e.key === "Enter") {
                                                                actualizarGiroComercial();
                                                            }
                                                            // console.log(e);
                                                        }
                                                    }
                                                //id="FormNombreLead"
                                                />

                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={
                                                        () => {
                                                            actualizarGiroComercial();
                                                        }
                                                    }
                                                >
                                                    <img src="/img/check25.png" width="20"></img>
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={
                                                        () => {
                                                            setEditarGiroComercial(false)
                                                        }
                                                    }
                                                >
                                                    ❌
                                                </Button>
                                            </InputGroup>

                                        </span>
                                    </>
                                    :
                                    cargandoModal ?
                                        <Skeleton />
                                        :
                                        <>
                                            <img width="25" src="/img/sucursal20.png"></img>&nbsp;

                                            <span className="fs-2">{detallesLead.vGiroComercial}</span>

                                            <OverlayTrigger placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        <strong>Editar Giro Comercial</strong>
                                                    </Tooltip>
                                                }>
                                                <span>

                                                    &nbsp;<img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => setEditarGiroComercial(true)}></img>
                                                </span>
                                            </OverlayTrigger>
                                        </>
                                }

                                {/*Correo eelectronico*/}
                                <br></br>
                                {editarCorreo ?
                                    <>
                                        <span>
                                            <InputGroup className="fs--1">
                                                <img width="30" src="/img/correo48.png"></img>&nbsp;

                                                <Form.Control
                                                    type="text"
                                                    size="sm"
                                                    defaultValue={Modal_Correo}
                                                    disabled={cargandoModal ? true : false}
                                                    autoComplete="off"
                                                    onChange={
                                                        (event) => {
                                                            setModal_Correo(event.target.value);
                                                        }
                                                    }
                                                    onKeyDown={
                                                        (e) => {

                                                            if (e.key === "Enter") {
                                                                actualizarCorreo();
                                                            }
                                                        }
                                                    }
                                                />

                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={
                                                        () => {
                                                            actualizarCorreo();
                                                        }
                                                    }
                                                >
                                                    <img src="/img/check25.png" width="25"></img>
                                                </Button>
                                                <Button
                                                    variant="outline-secondary"
                                                    size="sm"
                                                    onClick={
                                                        () => {
                                                            setEditarCorreo(false)
                                                        }
                                                    }
                                                >
                                                    ❌
                                                </Button>
                                            </InputGroup>

                                        </span>
                                    </>
                                    :
                                    cargandoModal ?
                                        <Skeleton />
                                        :
                                        <>
                                            <img width="25" src="/img/correo48.png"></img>&nbsp;

                                            <span className="fs-2">{detallesLead.vCorreo}</span>

                                            <OverlayTrigger placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        <strong>Editar Correo</strong>
                                                    </Tooltip>
                                                }>
                                                <span>

                                                    &nbsp;<img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => setEditarCorreo(true)}></img>
                                                </span>
                                            </OverlayTrigger>
                                        </>
                                }
                            </Col>
                            <Col>
                                {editarEtapaModal ?
                                    <InputGroup className="fs--1 align-items-center mb-0">
                                        <Form.Select
                                            size="sm"
                                            aria-label="Default select"
                                            value={detallesLead.idEtapa}
                                            onChange={
                                                async (event) => {

                                                    CambiarEtapa(event);
                                                }
                                            }
                                        >
                                            {
                                                catEtapas.map(
                                                    (etapa, index) => {
                                                        return (<option key={index} value={etapa.idEtapa}>{etapa.vNombreEtapa}</option>)

                                                    }
                                                )
                                            }
                                        </Form.Select>

                                        <span
                                            style={{ cursor: 'pointer', paddingLeft: 0, paddingTop: '1%' }}
                                            onClick={
                                                (event) => {
                                                    console.log(event);
                                                    setEditarEtapaModal(false);
                                                }
                                            }
                                            className="text-left ms-2"
                                        >
                                            ❌
                                        </span>

                                    </InputGroup>
                                    :
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={
                                            (event) => {
                                                //Double click
                                                if (event.detail === 2) {
                                                    setEditarEtapaModal(true);
                                                }
                                            }
                                        }
                                    >
                                        <SoftBadge pill bg={
                                            detallesLead.idEtapa == 1 ? "secondary" :
                                                detallesLead.idEtapa == 2 ? "primary" :
                                                    detallesLead.idEtapa == 3 ? "warning" :
                                                        detallesLead.idEtapa == 4 ? "success" : "danger"
                                        }
                                            className="mt-1"
                                        >
                                            <p className="mb-0 fs-1 p-1">
                                                {detallesLead.vNombreEtapa}
                                            </p>
                                        </SoftBadge>
                                    </span>
                                }
                                {cargandoModal ?
                                    <Skeleton />
                                    :
                                    <>
                                        {editarPropuestaEconomica ?
                                            <>

                                                <InputGroup className="fs--1 mt-2 mb-2 justify-content-center align-item-center align-self-center">

                                                    <span className="input-group-text">$</span>
                                                    <Form.Control
                                                        type="text"
                                                        size="sm"
                                                        defaultValue={Modal_PropuestaEconomica}
                                                        disabled={cargandoModal ? true : false}
                                                        autoComplete="off"
                                                        onChange={
                                                            (event) => {
                                                                setModal_PropuestaEconomica(event.target.value);
                                                            }
                                                        }
                                                        onKeyDown={
                                                            (e) => {

                                                                if (e.key === "Enter") {
                                                                    actualizarPropuestaEconomica();
                                                                }
                                                            }
                                                        }
                                                        id="FormNombreLead"
                                                    />
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        onClick={
                                                            () => {

                                                                actualizarPropuestaEconomica();

                                                            }
                                                        }
                                                    >
                                                        <img src="/img/check25.png" width="20"></img>
                                                    </Button>
                                                    <Button
                                                        variant="outline-secondary"
                                                        size="sm"
                                                        onClick={
                                                            () => {
                                                                setEditarPropuestaEconomica(false)
                                                            }
                                                        }
                                                    >
                                                        ❌
                                                    </Button>

                                                </InputGroup>
                                                <Row className="align-self-center align-items-center justify-content-center m-0 p-0">

                                                    <Col className="col-8">
                                                        <ToggleButton
                                                            className="m-1"
                                                            type="radio"
                                                            id="radio-1"
                                                            variant={'outline-primary'}
                                                            name="radio"
                                                            value="USD"
                                                            checked={Modal_Moneda === 'USD'}
                                                            onChange={() => {
                                                                setModal_Moneda("USD");

                                                            }}
                                                        >
                                                            USD
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            className="m-1"
                                                            type="radio"
                                                            id="radio-2"
                                                            variant={'outline-primary'}
                                                            name="radio"
                                                            value="MXN"
                                                            checked={Modal_Moneda === 'MXN'}
                                                            onChange={() => {
                                                                setModal_Moneda("MXN");

                                                            }}
                                                        >
                                                            MXN
                                                        </ToggleButton>

                                                    </Col>

                                                </Row>


                                            </>
                                            :
                                            <>
                                                <p className="fs-2 mt-2 mb-2">{detallesLead.dPropuestaEconomica} {detallesLead.vMoneda}
                                                    <span>
                                                        <img style={{ cursor: 'pointer' }} width={20} src="/img/editar20.png" onClick={() => setEditarPropuestaEconomica(true)}></img>
                                                    </span>
                                                </p>
                                            </>

                                        }

                                        <strong className="fs--1">{detallesLead.vNombreUsuario}</strong>
                                        <p className="fs--2 text-700">{"Registro: " + new Date(detallesLead.dCreatedDate).toLocaleDateString('es-mx', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) + " " + new Date(detallesLead.dCreatedDate).toLocaleTimeString('es-mx', { hour12: true })}</p>

                                    </>
                                }

                            </Col>
                        </Row>

                        {cargandoModal ? <h5><Spinner className="text-inline mt-3" animation="border" variant="primary" /> Cargando...</h5>
                            :
                            <Row>
                                <hr></hr>
                                <Col className="col-5">
                                    {Modal_BitAddTag ?
                                        <>
                                            <InputGroup className="fs--1 align-items-center">
                                                <Form.Select
                                                    size="sm"
                                                    aria-label="Default select"
                                                    className=""
                                                    value={Modal_valueTagSelected}
                                                    onChange={
                                                        (event) => {
                                                            console.log(event);
                                                            setModal_valueTagSelected(event.target.value);

                                                            const objDDLTag = TagsForModalDDL.find(t => t.idLeadTag == event.target.value);
                                                            console.log("objDDLTag: ", objDDLTag);

                                                            if (objDDLTag != null) {

                                                                var objLeadTag = {};
                                                                objLeadTag.idLeadTag = objDDLTag.idLeadTag;

                                                                var copyModal_JsonTags = [...Modal_JsonTags];
                                                                copyModal_JsonTags.push(objLeadTag);

                                                                setModal_valueTagSelected(-1);
                                                                actualizarJsonTags(copyModal_JsonTags, objDDLTag);
                                                                setModal_BitAddTag(false);
                                                            }
                                                        }
                                                    }
                                                >
                                                    <option value="-1">
                                                        Selecciona una Opción
                                                    </option>
                                                    {
                                                        TagsForModalDDL.map(
                                                            (tag, index) => {
                                                                if (tag.bIsActive == 1) {
                                                                    return (

                                                                        <option key={index} value={tag.idLeadTag}>
                                                                            {tag.vNombreTag}
                                                                        </option>
                                                                    )
                                                                }

                                                            }
                                                        )
                                                    }
                                                </Form.Select>

                                                <button
                                                    onClick={
                                                        () => {
                                                            setModal_BitAddTag(false);
                                                        }
                                                    }
                                                    className="d-sm-block me-2 btn btn-falcon-default btn-sm">
                                                    x
                                                </button>
                                            </InputGroup>

                                        </>
                                        :
                                        <button
                                            onClick={
                                                () => {
                                                    setModal_BitAddTag(true);
                                                }
                                            }
                                            className="d-sm-block me-2 btn btn-falcon-default btn-sm">
                                            Agregar etiqueta
                                        </button>

                                    }

                                </Col>
                                <Col className="col-12 mb-3">

                                    {
                                        Modal_JsonTags.map(
                                            (tag, index) => {

                                                const objDDLTag = tags.find(t => t.idLeadTag == tag.idLeadTag);

                                                return (
                                                    <span
                                                        key={index}
                                                    >
                                                        <SoftBadge
                                                            className={"me-3 bgTag_" + index}
                                                            bg={objDDLTag.colorActive}

                                                        >
                                                            {objDDLTag.vNombreTag}&nbsp;
                                                            <span
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={
                                                                    () => {
                                                                        const copyModal_JsonTags = Modal_JsonTags.filter(item => item.idLeadTag !== objDDLTag.idLeadTag)
                                                                        actualizarJsonTags(copyModal_JsonTags, objDDLTag);
                                                                    }
                                                                }>❌</span>
                                                        </SoftBadge>
                                                    </span >


                                                )
                                            }
                                        )
                                    }
                                </Col>
                                <hr />
                                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 border-end" >
                                    <Row>
                                        <Col className="col-12 fs--1">
                                            <h5><img width="30" src="/img/calendario48.png"></img>&nbsp;Eventos</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {eventosActivos.length > 0 ?
                                            eventosActivos.map((event, index) => (
                                                <Col key={index} className="h-100 mt-2 col-12">
                                                    <Event
                                                        details={event}
                                                        GuardarComentario={GuardarComentario}
                                                        TerminarEvento={TerminarEvento}
                                                        idLead={detallesLead.idLead}
                                                        procesandoModal={procesandoModal}
                                                        habilitarBtnTerminarEvento={habilitarBtnTerminarEvento}
                                                        editarEventos={editarEventos}
                                                    />
                                                </Col>
                                            ))
                                            :
                                            <Col md={12}>

                                                {
                                                    !showAgregarEvento &&
                                                    <>

                                                        <button
                                                            onClick={
                                                                () => {
                                                                    setFormDataEvent({
                                                                        ...formDataEvent,
                                                                        dFechaEvento: new Date(),
                                                                        vTituloEvento: '',
                                                                        vDescripcionEvento: ''
                                                                    });
                                                                    setShowAgregarEvento(true);
                                                                }
                                                            }
                                                            className="d-sm-block me-2 btn btn-falcon-default btn-sm">
                                                            Crear evento
                                                        </button>


                                                    </>

                                                }
                                            </Col>
                                        }

                                    </Row>

                                </Col>

                                <hr className="d-block d-md-none mt-3" />

                                <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <Row>
                                        <Col className="col-12 fs--1 mb-3">
                                            <h5><FontAwesomeIcon icon={icon({ name: 'link', style: "solid" })} size="lg" /> Vinculados</h5>
                                        </Col>

                                        <Col className="col-12 fs--1 mb-1" style={{ cursor: 'pointer' }} >

                                            {

                                                habilitarVinculacion == 1 ?

                                                    <>
                                                        <Row className="mb-2 mt-2">
                                                            <Col className="col-11">
                                                                <InputGroup className="fs--1 align-items-center">

                                                                    <Form.Control
                                                                        type="number"
                                                                        size="sm"
                                                                        disabled={cargandoModal ? true : false}
                                                                        autoComplete="off"
                                                                        placeholder="Número de Lead"
                                                                        value={idLeadVincular}
                                                                        onChange={
                                                                            (e) => {
                                                                                setIdLeadVincular(e.target.value);
                                                                            }
                                                                        }
                                                                        onKeyDown={
                                                                            (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    vincularLead();
                                                                                }
                                                                            }
                                                                        }
                                                                    />
                                                                    {
                                                                        procesandoVincular ?
                                                                            <>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    Procesando...
                                                                                </Button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Button
                                                                                    disabled={procesandoVincular ? true : false}
                                                                                    className="btn btn-primary"
                                                                                    size="sm"
                                                                                    type="submit"
                                                                                    onClick={
                                                                                        () => {
                                                                                            vincularLead();

                                                                                        }
                                                                                    }

                                                                                >
                                                                                    Vincular

                                                                                </Button>

                                                                                <FontAwesomeIcon className="ms-2"
                                                                                    onClick={
                                                                                        () => { setHabilitarVinculacion(0); setIdLeadVincular(""); }}
                                                                                    icon={icon({ name: 'xmark', style: "solid" })}
                                                                                    style={{ color: "#c32847", }}
                                                                                    size="xl" />

                                                                            </>
                                                                    }


                                                                </InputGroup>
                                                            </Col>
                                                        </Row>

                                                    </>

                                                    :
                                                    <>
                                                        {
                                                            listaJsonLeadsVinculados.length >= 1 ?
                                                                <>
                                                                    <span>

                                                                        <Link to={"#"} onClick={() => ObtenerDatosLead(listaJsonLeadsVinculados[0].idLead)}>
                                                                            <FontAwesomeIcon className="me-2"
                                                                                icon={icon({ name: 'headset', style: "solid" })}
                                                                                style={{ color: "#000000", }}
                                                                                size="lg" />

                                                                            <span className="text-dark fw-bold ">{"Lead #" + listaJsonLeadsVinculados[0].idLead}</span>

                                                                            <SoftBadge
                                                                                className="ms-1"
                                                                                bg={
                                                                                    listaJsonLeadsVinculados[0].iDaysDiff >= 0 && listaJsonLeadsVinculados[0].iDaysDiff < 3 ? "success" :
                                                                                        listaJsonLeadsVinculados[0].iDaysDiff >= 3 && listaJsonLeadsVinculados[0].iDaysDiff < 5 ? "warning" : "danger"}
                                                                            >
                                                                                {listaJsonLeadsVinculados[0].iDaysDiff + " días sin actualizaciones"}
                                                                            </SoftBadge>
                                                                        </Link>
                                                                        {
                                                                            procesandoDesvincular ?
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"

                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#9da9bb", }}
                                                                                        disabled
                                                                                        size="lg" />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"
                                                                                        onClick={
                                                                                            () => desvincularLead(listaJsonLeadsVinculados[0].idLead)}
                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#c32847", }}
                                                                                        size="lg" />
                                                                                </>
                                                                        }



                                                                    </span>
                                                                </>
                                                                :
                                                                <>
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Vincular lead</Tooltip>}>
                                                                        <span onClick={() => { setHabilitarVinculacion(1) }}>
                                                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'headset', style: "solid" })} style={{ color: "#000000", }} size="lg" />
                                                                            <span className="text-muted ">Click para vincular lead</span>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </>
                                                        }


                                                    </>
                                            }

                                        </Col>
                                        <Col className="col-12 fs--1 mb-1" style={{ cursor: 'pointer' }} >
                                            {
                                                habilitarVinculacion == 2 ?
                                                    <>


                                                        <Row className="mb-2 mt-2">
                                                            <Col className="col-11 ">
                                                                <InputGroup className="fs--1 align-items-center">

                                                                    <Form.Control
                                                                        type="number"
                                                                        size="sm"
                                                                        disabled={cargandoModal ? true : false}
                                                                        autoComplete="off"
                                                                        placeholder="Número de Lead"
                                                                        value={idLeadVincular}
                                                                        onChange={
                                                                            (e) => {
                                                                                setIdLeadVincular(e.target.value);
                                                                            }
                                                                        }
                                                                        onKeyDown={
                                                                            (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    vincularLead();
                                                                                }
                                                                            }
                                                                        }
                                                                    />
                                                                    {
                                                                        procesandoVincular ?
                                                                            <>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    disabled>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    Procesando...
                                                                                </Button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Button
                                                                                    disabled={procesandoVincular ? true : false}
                                                                                    className="btn btn-primary"
                                                                                    size="sm"
                                                                                    type="submit"
                                                                                    onClick={
                                                                                        () => {
                                                                                            vincularLead();

                                                                                        }
                                                                                    }

                                                                                >
                                                                                    Vincular

                                                                                </Button>

                                                                                <FontAwesomeIcon className="ms-2"
                                                                                    onClick={
                                                                                        () => { setHabilitarVinculacion(0); setIdLeadVincular(""); }}
                                                                                    icon={icon({ name: 'xmark', style: "solid" })}
                                                                                    style={{ color: "#c32847", }}
                                                                                    size="xl" />
                                                                            </>
                                                                    }


                                                                </InputGroup>
                                                            </Col>
                                                        </Row>

                                                    </>
                                                    :
                                                    <>

                                                        {
                                                            listaJsonLeadsVinculados.length >= 2 ?
                                                                <>
                                                                    <span>

                                                                        <Link to={"#"} onClick={() => ObtenerDatosLead(listaJsonLeadsVinculados[1].idLead)}>
                                                                            <FontAwesomeIcon className="me-2"
                                                                                icon={icon({ name: 'headset', style: "solid" })}
                                                                                style={{ color: "#000000", }}
                                                                                size="lg" />

                                                                            <span className="text-dark fw-bold ">{"Lead #" + listaJsonLeadsVinculados[1].idLead}</span>

                                                                            <SoftBadge
                                                                                className="ms-1"
                                                                                bg={
                                                                                    listaJsonLeadsVinculados[1].iDaysDiff >= 0 && listaJsonLeadsVinculados[1].iDaysDiff < 3 ? "success" :
                                                                                        listaJsonLeadsVinculados[1].iDaysDiff >= 3 && listaJsonLeadsVinculados[1].iDaysDiff < 5 ? "warning" : "danger"}
                                                                            >
                                                                                {listaJsonLeadsVinculados[1].iDaysDiff + " días sin actualizaciones"}
                                                                            </SoftBadge>
                                                                        </Link>
                                                                        {
                                                                            procesandoDesvincular ?
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"

                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#9da9bb", }}
                                                                                        disabled
                                                                                        size="lg" />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"
                                                                                        onClick={
                                                                                            () => desvincularLead(listaJsonLeadsVinculados[1].idLead)}
                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#c32847", }}
                                                                                        size="lg" />
                                                                                </>
                                                                        }

                                                                    </span>
                                                                </>
                                                                :
                                                                <>
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Vincular lead</Tooltip>}>
                                                                        <span onClick={() => { setHabilitarVinculacion(2) }}>
                                                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'headset', style: "solid" })} style={{ color: "#000000", }} size="lg" />
                                                                            <span className="text-muted ">Click para vincular lead</span>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </>
                                                        }


                                                    </>
                                            }
                                        </Col>
                                        <Col className="col-12 fs--1" style={{ cursor: 'pointer' }} >
                                            {
                                                habilitarVinculacion == 3 ?
                                                    <>
                                                        <Row className="mb-2 mt-2">
                                                            <Col className="col-11">
                                                                <InputGroup className="fs--1 align-items-center">

                                                                    <Form.Control
                                                                        type="number"
                                                                        size="sm"
                                                                        disabled={cargandoModal ? true : false}
                                                                        autoComplete="off"
                                                                        placeholder="Número de Lead"
                                                                        value={idLeadVincular}
                                                                        onChange={
                                                                            (e) => {
                                                                                setIdLeadVincular(e.target.value);
                                                                            }
                                                                        }
                                                                        onKeyDown={
                                                                            (e) => {
                                                                                if (e.key === "Enter") {
                                                                                    vincularLead();
                                                                                }
                                                                            }
                                                                        }
                                                                    />

                                                                    {
                                                                        procesandoVincular ?
                                                                            <>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    size="sm"
                                                                                    disabled>

                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    Procesando...

                                                                                </Button>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Button
                                                                                    disabled={procesandoVincular ? true : false}
                                                                                    className="btn btn-primary"
                                                                                    size="sm"
                                                                                    type="submit"
                                                                                    onClick={
                                                                                        () => {
                                                                                            vincularLead();

                                                                                        }
                                                                                    }

                                                                                >
                                                                                    Vincular

                                                                                </Button>

                                                                                <FontAwesomeIcon className="ms-2"
                                                                                    onClick={
                                                                                        () => { setHabilitarVinculacion(0); setIdLeadVincular(""); }}

                                                                                    icon={icon({ name: 'xmark', style: "solid" })}
                                                                                    style={{ color: "#c32847", }}
                                                                                    size="xl" />
                                                                            </>
                                                                    }


                                                                </InputGroup>
                                                            </Col>
                                                        </Row>

                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            listaJsonLeadsVinculados.length >= 3 ?
                                                                <>
                                                                    <span>

                                                                        <Link to={"#"} onClick={() => ObtenerDatosLead(listaJsonLeadsVinculados[2].idLead)}>
                                                                            <FontAwesomeIcon className="me-2"
                                                                                icon={icon({ name: 'headset', style: "solid" })}
                                                                                style={{ color: "#000000", }}
                                                                                size="lg" />

                                                                            <span className="text-dark fw-bold ">{"Lead #" + listaJsonLeadsVinculados[2].idLead}</span>

                                                                            <SoftBadge
                                                                                className="ms-1"
                                                                                bg={
                                                                                    listaJsonLeadsVinculados[2].iDaysDiff >= 0 && listaJsonLeadsVinculados[2].iDaysDiff < 3 ? "success" :
                                                                                        listaJsonLeadsVinculados[2].iDaysDiff >= 3 && listaJsonLeadsVinculados[2].iDaysDiff < 5 ? "warning" : "danger"}
                                                                            >
                                                                                {listaJsonLeadsVinculados[2].iDaysDiff + " días sin actualizaciones"}
                                                                            </SoftBadge>
                                                                        </Link>
                                                                        {
                                                                            procesandoDesvincular ?
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"

                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#9da9bb", }}
                                                                                        disabled
                                                                                        size="lg" />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <FontAwesomeIcon className="ms-2"
                                                                                        onClick={
                                                                                            () => desvincularLead(listaJsonLeadsVinculados[2].idLead)}
                                                                                        icon={icon({ name: 'trash-can', style: "solid" })}
                                                                                        style={{ color: "#c32847", }}
                                                                                        size="lg" />
                                                                                </>
                                                                        }

                                                                    </span>
                                                                </>
                                                                :
                                                                <>
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">Vincular lead</Tooltip>}>
                                                                        <span onClick={() => { setHabilitarVinculacion(3) }}>
                                                                            <FontAwesomeIcon className="me-2" icon={icon({ name: 'headset', style: "solid" })} style={{ color: "#000000", }} size="lg" />
                                                                            <span className="text-muted ">Click para vincular lead</span>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </>
                                                        }

                                                    </>
                                            }
                                        </Col>


                                    </Row>
                                </Col>

                            </Row>

                        }
                    </Modal.Body>
                    <FeedProvider>

                        {cargandoModal ?
                            <Skeleton />
                            :
                            <FeedCard
                                comentarios={comentarios}
                                procesandoComentario={procesandoComentario}
                                setComentario={setComentario}
                                comentario={comentario}
                                submitComment={submitComment}
                                idUsuario={Number(idUsuario)}

                            />
                        }
                    </FeedProvider>
                    <Modal.Footer>

                        {procesandoModal ?
                            <Button variant="primary" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Procesando...
                            </Button>
                            :
                            <Button onClick={() => { closeModalLead(); }} variant='primary'>
                                Cerrar
                            </Button>}
                    </Modal.Footer>

                </Modal >
            }
            {/*Se agrega Modal para los eventos*/}
            <Modal
                show={showAgregarEvento}
                onHide={() => { setShowAgregarEvento(false); setEditarEvento(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">{editarEvento ? <>Editar Evento de {detallesLead.vNombreLead}</> : <>Crear evento para {detallesLead.vNombreLead}</>}</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowAgregarEvento(false); setEditarEvento(false); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Row>
                            <Col>
                                <Form.Group controlId="vTituloEvento">
                                    <Form.Label>T&iacute;tulo del evento</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vTituloEvento"
                                        placeholder="Título del evento"
                                        defaultValue={formDataEvent.vTituloEvento}
                                        onBlur={(event) => handleChange('vTituloEvento', event.target.value)}
                                    />
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="vDescripcionEvento">
                                    <Form.Label>Descripci&oacute;n del evento</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="vDescripcionEvento"
                                        placeholder="Descripci&oacute;n del evento"

                                        defaultValue={formDataEvent.vDescripcionEvento}
                                        onBlur={(event) => handleChange('vDescripcionEvento', event.target.value)}
                                    />
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="dFechaEvento">
                                    <Form.Label>Fecha del Evento</Form.Label><br></br>
                                    <DatePicker
                                        selected={formDataEvent.dFechaEvento}
                                        showTimeSelect
                                        timeIntervals={15}
                                        dateFormat="dd MMM yyyy HH:mm"
                                        onChange={newDate => {


                                            handleChange('dFechaEvento', new Date(newDate));
                                        }}
                                        customInput={
                                            <CustomDateInput
                                                formControlProps={{
                                                    placeholder: 'dd/mm/yyyy hh:mm'
                                                }}
                                            />
                                        }
                                    />
                                </Form.Group>
                            </Col>

                        </Row>

                    </>
                </Modal.Body>
                <Modal.Footer>



                    {
                        !habilitarBtnEvent ?
                            <>
                                <Button variant='danger' className="mt-2" disabled>Cancelar</Button>

                                <button
                                    disabled
                                    className="d-sm-block me-2 btn btn-falcon-default btn-sm">
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    &nbsp;Procesando...
                                </button>

                            </>
                            :
                            <>
                                <Button variant='danger' className="mt-2" onClick={() => { setShowAgregarEvento(false); setHabilitarBtnEvent(true); setEditarEvento(false); }}>Cancelar</Button>
                                &nbsp;&nbsp;
                                <Button variant='success' className="mt-2" onClick={() => editarEvento ? RegistrarEvento(formDataEvent) : ValidarEventoRepetido(formDataEvent)}>{editarEvento ? <>Guardar</> : <>Registrar evento</>}</Button>

                            </>
                    }





                </Modal.Footer>
            </Modal>

            {/* Modal de advertencia que ya existe un evento a esa hora*/}
            <Modal
                show={showWarningEventoRepetido}
                onHide={() => setShowWarningEventoRepetido(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <FontAwesomeIcon className="fa-2x" icon="fa-solid fa-circle-exclamation" style={{ color: "#ffd700", }} />
                    <Modal.Title id="contained-modal-title-vcenter">&nbsp;&nbsp;Evento a la misma hora</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowWarningEventoRepetido(false); setShowAgregarEvento(true); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Row>

                            {
                                eventosMismaHora.map((event, index) => (
                                    <Col key={index} className="h-100 mt-2 fs--2 border-end col-6">

                                        <Row>
                                            <Col className="col-12">
                                                {"Lead " + event.idLead}
                                            </Col>
                                            <Col className="col-12">
                                                {event.vNombreLead}
                                            </Col>
                                        </Row>


                                        <Row className="justify-content-center">

                                            <Col className="col-5 col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 align-item-center align-self-center">
                                                <Card className="m-0 p-0 align-self-center">

                                                    <Card.Header className="m-0 p-0 text-center text-light fw-bold" style={{ backgroundColor: "#e63757", fontSize: "0.8em" }}>
                                                        {

                                                            new Date(event.dFechaEvento).getMonth() == 0 ? "ENE" :
                                                                new Date(event.dFechaEvento).getMonth() == 1 ? "FEB" :
                                                                    new Date(event.dFechaEvento).getMonth() == 2 ? "MAR" :
                                                                        new Date(event.dFechaEvento).getMonth() == 3 ? "ABR" :
                                                                            new Date(event.dFechaEvento).getMonth() == 4 ? "MAY" :
                                                                                new Date(event.dFechaEvento).getMonth() == 5 ? "JUN" :
                                                                                    new Date(event.dFechaEvento).getMonth() == 6 ? "JUL" :
                                                                                        new Date(event.dFechaEvento).getMonth() == 7 ? "AGO" :
                                                                                            new Date(event.dFechaEvento).getMonth() == 8 ? "SEP" :
                                                                                                new Date(event.dFechaEvento).getMonth() == 9 ? "OCT" :
                                                                                                    new Date(event.dFechaEvento).getMonth() == 10 ? "NOV" :
                                                                                                        new Date(event.dFechaEvento).getMonth() == 11 ? "DIC" : ''}
                                                    </Card.Header>
                                                    <Card.Body className="m-0 p-0 text-center border border-danger fw-bold text-muted" style={{ fontSize: "1.2em" }}>
                                                        {new Date(event.dFechaEvento).getDate()}
                                                    </Card.Body>

                                                </Card>

                                            </Col>

                                            <Col className="col-12 col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 p-0">

                                                <Row>

                                                    <Col className="col-12 text-xxl-start text-xl-start text-lg-start text-md-start text-sm-center col">
                                                        <span className="fw-bold fs--2">

                                                            <span className="">{event.vTituloEvento}</span>

                                                        </span>
                                                    </Col>

                                                </Row>

                                            </Col>

                                            <Col className="col-12 mt-1">
                                                <img width={20} src="/img/reloj48.png"></img>
                                                {
                                                    new Date(event.dFechaEvento).toLocaleTimeString(
                                                        'es-mx',
                                                        {
                                                            hour12: true
                                                        }
                                                    )
                                                }

                                            </Col>

                                        </Row>

                                        <br />
                                    </Col>
                                ))
                            }
                        </Row>

                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' className="mt-2" onClick={() => { setShowWarningEventoRepetido(false); setShowAgregarEvento(true); }}>Cancelar</Button>
                    &nbsp;&nbsp;<Button variant='success' className="mt-2" onClick={() => RegistrarEvento(formDataEvent)}>Continuar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para escribir un comentario al momento de asignar un nuevo vendedor a un lead*/}
            <Modal
                show={showModalComentarioCambioVendedor}
                onHide={() => { setShowModalComentarioCambioVendedor(false); setComentarioCambioVendedor(''); setShowModalAsignarmeLeadsVinculados(true); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Escribe una actualización a este lead para podertelo asignar </Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowModalComentarioCambioVendedor(false); setComentarioCambioVendedor(''); setShowModalAsignarmeLeadsVinculados(true); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={(event) => setComentarioCambioVendedor(event.target.value)}
                            value={comentarioCambioVendedor}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => { setShowModalComentarioCambioVendedor(false); setComentarioCambioVendedor(''); setShowModalAsignarmeLeadsVinculados(false); }}>Cancelar</Button>

                    {
                        habilitarBtnGuardarComentario ?
                            <Button variant='success' onClick={() => { cambiarVendedorLead(); }}>Guardar Comentario</Button>
                            :
                            <>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                            </>
                    }

                </Modal.Footer>
            </Modal>

            {/* Modal para confirmar liberar los leads*/

                <Modal
                    show={showModalLiberarLeads}
                    onHide={() => setShowModalLiberarLeads(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Leads vinculados</Modal.Title>
                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => setShowModalLiberarLeads(false)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Esta acción liberará los leads vinculados a este lead, ¿estas seguro que deseas continuar?</h4>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='danger' onClick={() => setShowModalLiberarLeads(false)}>Cancelar</Button>

                        {
                            habilitarBtnContinuarLiberarLead ?
                                <Button variant='success' onClick={() => liberarLead()}>Continuar</Button>
                                :
                                <>
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Procesando...
                                    </Button>
                                </>
                        }

                    </Modal.Footer>
                </Modal>
            }

            {/* Modal para confirmar asignacion de los leads vinculados*/

                <Modal
                    show={showModalAsignarmeLeadsVinculados}
                    onHide={() => setShowModalAsignarmeLeadsVinculados(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Leads vinculados</Modal.Title>
                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => setShowModalAsignarmeLeadsVinculados(false)}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Esta acción te asignará los leads vinculados de este lead, ¿deseas continuar?</h4>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='danger' onClick={() => setShowModalAsignarmeLeadsVinculados(false)}>Cancelar</Button>
                        <Button variant='success' onClick={() => { setShowModalComentarioCambioVendedor(true); setShowModalAsignarmeLeadsVinculados(false); }}>Continuar</Button>

                    </Modal.Footer>
                </Modal>
            }
            {/* Modal para escoger un cliente y asignarlo a un lead, posiblemente cambiar la etapa o solo vincularlos*/

                <ModalClienteGanado
                    showEtapaClienteGanadoModal={showEtapaClienteGanadoModal}
                    setShowEtapaClienteGanadoModal={setShowEtapaClienteGanadoModal}
                    setMensajeError={setMensajeError}
                    setShowMensajeError={setShowMensajeError}
                    idLead={idLead}
                    vNombreEtapa={vNombreEtapa}
                    setContactos={setContactos}
                    listaJsonLeadsVinculados={listaJsonLeadsVinculados}
                    detallesLead={detallesLead}
                    GuardarComentario={GuardarComentario}
                    setvNombreEtapa={setvNombreEtapa}
                    setEditarEtapaModal={setEditarEtapaModal}
                    contactos={contactos}
                    setMensajeInformativo={setMensajeInformativo}
                    setShowMensajeInformativo={setShowMensajeInformativo}
                    guardarNotificacion={guardarNotificacion}
                    crearObjAction={crearObjAction }
                    
                />
            }
            {/* Modal para escribir un comentario de porque un cliente no esta interesado*/}
            <Modal
                show={showEtapaNoInteresadoModal}
                onHide={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">¿Por qué no estuvo interesado el Lead?</Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            onChange={(event) => setMotivoNoInteresado(event.target.value)}
                            value={motivoNoInteresado}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => { setShowEtapaNoInteresadoModal(false); setMotivoNoInteresado(''); }}>Cancelar</Button>

                    {
                        habilitarBtnGuardar ?
                            <Button variant='success' onClick={() => { etapaNoInteresado(idLead); }}>Guardar Motivo</Button>
                            :
                            <>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Procesando...
                                </Button>
                            </>
                    }

                </Modal.Footer>
            </Modal>
        </>
    );
};

ModalSeguimientoLead.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    catEtapas: PropTypes.array,
    contactos: PropTypes.array,
    setContactos: PropTypes.func,
    lista3LeadsLiberados: PropTypes.array,
    listaEventos: PropTypes.array,
    setListaEventos: PropTypes.func,
    setProximoEvento: PropTypes.func,
    proximoEvento: PropTypes.array,
    listaEventosPasados: PropTypes.array,
    setListaEventosPasados: PropTypes.func,
    listaEventosHoy: PropTypes.array,
    setListaEventosHoy: PropTypes.func,
    listaEventosFuturos: PropTypes.array,
    setListaEventosFuturos: PropTypes.func,
    listaLeadsLiberados: PropTypes.array,
    setListaLeadsLiberados: PropTypes.func,
    mostrarLeads: PropTypes.func,
    Filter_Tags: PropTypes.array,
    filtroEventosVencidos: PropTypes.bool,
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func,
    idLead: PropTypes.number,
    setIdLead: PropTypes.func,
    leadsVinculados: PropTypes.array,
    setLeadsVinculados: PropTypes.func
    //setModalAbierto: PropTypes.func
};

ModalSeguimientoLead.defaultProps = { };

export default ModalSeguimientoLead;
