import Divider from 'components/common/Divider';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import useToken from 'hooks/useToken';
import { Link } from 'react-router-dom';

import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebase/firebaseConfig";
import MensajeErrror from 'components/common/MensajeError';

const LoginForm = () => {
    // State
    const [formData, setFormData] = useState({
        vUsuario: '',
        vContrasena: '',
        remember: false
    });
    const { setToken } = useToken();
    const [mensajeError, setMensajeError] = useState('');
    const [showMensajeError, setShowMensajeError] = useState(false);
   // const { tokenFireBase, setTokenFireBase } = useState('');

    const [enviandoInfo, setEnviandoInfo] = useState(false);
    const VITE_APP_VAPID_KEY = "BDgxI0yryiAvMlu9vmMDZibB-bmaSt5pj1Vrun_IsGWmzS3h5ubI69nRxId9HteusWK3d1cA1_Y1tFMdV4UjUkE";

    /**
   * Guarda el token en la BD
   * @param {any} token
   */
    const guardarToken = async (tokenFireBase, token) => {

        var jDataSend = {};

        jDataSend.tokenFireBase = tokenFireBase;

        const response = await fetch("/api/account/GuardarToken",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jDataSend)
            }).catch((error) => {

                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {

            console.log("==== GUARDAR TOKEN ====");
            console.log(response);
            const data = await response.json();
            console.log(data);

            if (data.iResponseType == 1) {

                console.log(data.vMessage);
                //setTokenFireBase(tokenFireBase);

            } else if (data.iResponseType == 3) {

                setShowMensajeError(true);
                setMensajeError(data.vMessage);

                setToken(null);
                location.href = "";

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

        }
        else {
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
            console.log(response);
        }
    }

    /**
     * genera el token de firebase
     * @param {any} token
     */
    async function requestValidateToken(token) {
        //requesting permission using Notification API
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            const tokenFirebase = await getToken(messaging, {
                vapidKey: VITE_APP_VAPID_KEY,
            });

            //We can send token to server
            console.log("Token generado : ", tokenFirebase);
            guardarToken(tokenFirebase, token);

        } else if (permission === "denied") {
        //    //notifications are blocked
            alert("No tienes permisos para recibir notificaciones, si quieres esta funci�n debes configuraralo en tu explordor!s");
        }
    }

    const enviarDatosAutenticacion = async () => {
 
        setEnviandoInfo(true);
        const response = await fetch("/api/account/authenticate", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)

        })
            .catch((error) => {

                console.log('Hubo un problema con la peticion Fetch: ' + error.message);
                setEnviandoInfo(false);
            });

       

        if (response.ok) {
            console.log(response);

            const data = await response.json();
            console.log("data login form: ",data);
            setToken(data);

            requestValidateToken(data.vToken);

            sessionStorage.setItem("vUsuario", data.vUsuario);

            //f (data != null) {

            toast.success('AUTENTICADO COMO ' + data.vNombre + ' ' + data.vApellidos, {
                theme: 'colored',
                position: "bottom-left"
            });

            setTimeout(function () { location.href = "" }, 2000)
           // }

            
            
        }
        else {
            toast.error('Ooops! Datos Incorrectos', {
                theme: 'colored',
                position: "bottom-left"
            });
            console.log(response);
            setEnviandoInfo(false);
        }
        
    };

    // Handler
    const handleSubmit = (e) => {
        e.preventDefault();
        enviarDatosAutenticacion();

    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Control
                        placeholder={'Usuario'}
                        value={formData.vUsuario}
                        name="vUsuario"
                        onChange={handleFieldChange}
                        type="text"
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        placeholder={'Contrase\u00f1a'}
                        value={formData.vContrasena}
                        name="vContrasena"
                        onChange={handleFieldChange}
                        type="password"
                    />
                </Form.Group>

                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                            <Form.Check.Input
                                type="checkbox"
                                name="remember"
                                checked={formData.remember}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        remember: e.target.checked
                                    })
                                }
                            />
                            <Form.Check.Label className="mb-0 text-700">
                                Recuerdame
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>

                    <Col xs="auto">
                        <Link
                            className="fs--1 mb-0"
                            to={`/authentication/simple/forgot-password`}
                        >
                            &iquest;Olvisdaste la contrase&ntilde;a?
                        </Link>
                    </Col>
                </Row>

                <Form.Group>
                    <Button
                        type="submit"
                        color="primary"
                        className="mt-3 w-100"
                        disabled={!formData.vUsuario || !formData.vContrasena || enviandoInfo}
                    >
                        Entrar al sistema
                    </Button>
                </Form.Group>

                <Divider className="mt-4">Akasia Software Development, Sa de Cv</Divider>

            </Form>
            <MensajeErrror setShowMensajeError={setShowMensajeError} showMensajeError={showMensajeError} mensajeError={mensajeError}></MensajeErrror>
            <ToastContainer />
        </>
    );
};



export default LoginForm;
