import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notification from 'akasia-components/notification/Notification';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
//import { isIterableArray } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, ListGroup, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from 'react-loading-skeleton'

const NotificationDropdown = ({
    notificaciones,
    setIdLead,
    setShowModalSeguimientoLead,
    isAllRead,
    setIsAllRead,
    hayNotificacionesNoLeidas,
    obtenerNotificaciones,
    lastNotf
    //isModalOpen,
    //setIsModalOpen
}) => {

    const { token } = useToken();

    const [isOpen, setIsOpen] = useState(false);
    //const [isModalOpen, setIsModalOpen] = useState(false);
    
    //const [notificaciones, setNotificaciones] = useState([]);
    //const [expandedSections, setExpandedSections] = useState({});

    // Handler
    const handleToggle = (isOpen_, e) => {
        //if (!isModalOpen) {//si no esta abierto el modal funciona normalmente,si esta abierto no cierres el dropdown de la campana de notificaciones
        //    setIsOpen(!isOpen);
        //}
        //console.log(isOpen_);
        //setIsOpen(!isOpen);
        //console.log(event);
        //if (e.originalEvent.target.tagName == 'path' || e.originalEvent.target.tagName == 'SPAN' || e.originalEvent.target.tagName == 'svg') {
        //    setIsOpen(!isOpen);
        //}

        if (e.originalEvent.target.id != 'cerrarModal') {
            setIsOpen(!isOpen);
        }
        console.log(e);
        //console.log("modalAbierto:", modalAbierto);
        //if (modalAbierto != "modalSeguimientoLead") {//si modalAbierto no es "modalSeguimientoLead" cierra el dropdown, si es el modal abierto no cierres el dropdown
            //setIsOpen(!isOpen);
        //}
        
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            window.innerWidth < 1200 && setIsOpen(false);
        });

    }, []);

    const markAsRead = e => {
        e.preventDefault();
        setIsAllRead(0);
        marcarNotificacionesComoMostradas();

    };

    /**
    * Marca como leidas todas las notificaciones del usuario que inicio sesion 
    */
    const marcarNotificacionesComoMostradas = async () => {

        const response = await fetch("/api/account/MarcarNotificacionesComoMostradas/",
            {
                method: 'POST',
                headers:
                {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }

            }).catch((error) => {

                //setMensajeError(error);
                //setShowMensajeError(true);
                console.log('Hubo un problema con la peticion Fetch:' + error);
            });

        if (response.ok) {
            console.log("==== NOTIFICACIONES MARCADAS COMO LEIDAS ====");

            console.log("response notificaciones no leidas: ", response);

            const data = await response.json();
            console.log("data notificaciones no leidas: ", data);

            if (data.iResponseType == 1) {

                //var detalles = JSON.parse(data.detalles);
                //console.log("detalles: ", detalles);

                //setIsAllRead(detalles[0].TotalRegistros);
                console.log("se marcaron como leidas todas las notificaiones");
                obtenerNotificaciones(1);

            } else {

                console.log("Error: ", data.vMessage);

            }

        }
        else {

            //setMensajeError(response.status + ' ' + response.statusText);
            //setShowMensajeError(true);

            console.log(response);
        }
    }

    //const isIterableArray = (arr) => {
    //    return Array.isArray(arr) && arr.length > 0;
    //};

    // Funci�n para alternar la expansi�n de una secci�n
    //const toggleSection = (titulo) => {
    //    setExpandedSections(prevState => ({
    //        ...prevState,
    //        [titulo]: !prevState[titulo]
    //    }));
    //};

    return (
        <div id={"bell"}>

            <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle} >
                <Dropdown.Toggle
                    bsPrefix="toggle"
                    as={Link}
                    to="#!"
                    className={classNames('px-0 nav-link', {
                        /*'notification-indicator notification-indicator-primary': !isAllRead*/
                    })}
                >
                    <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
                    <span className="notification-indicator-number text-danger fw-bold pb-3">{isAllRead > 0 && isAllRead < 99 ? isAllRead : isAllRead > 99 ? "99+" : ""}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
                    <Card
                        className="dropdown-menu-notification dropdown-menu-end shadow-none"
                        style={{ maxWidth: '20rem' }}
                    >
                        <FalconCardHeader
                            className="card-header"
                            title="Notificaciones"
                            titleTag="h6"
                            light={false}
                            endEl={
                                <Link
                                    className="card-link fw-normal"
                                    to="#!"
                                    onClick={markAsRead}
                                >
                                    Marcar como leidas
                                </Link>
                            }
                        />
                        <ListGroup id={"targetScrollNtfBell"} variant="flush" className="fw-normal fs--1 scrollbar" style={{ maxHeight: '19rem' }}>

                            <InfiniteScroll
                                dataLength={notificaciones.length}
                                next={() => obtenerNotificaciones(0)}
                                hasMore={lastNotf ? true : false}
                                loader={<NotificationSkeleton />}
                                //loader={"Cargando..."}
                                scrollableTarget={"targetScrollNtfBell"}
                                scrollThreshold={0.9}
                            >
                                {/*console.log("notificaciones campana: ", notificaciones)*/}
                                {
                                    notificaciones.map(notificacion => (
                                        <Notification
                                            key={notificacion.idNotificacion}
                                            notification={notificacion}
                                            setIdLead={setIdLead}
                                            setShowModalSeguimientoLead={setShowModalSeguimientoLead}
                                            hayNotificacionesNoLeidas={hayNotificacionesNoLeidas}
                                        //setIsModalOpen={setIsModalOpen}
                                        />
                                    ))
                                }
                            </InfiniteScroll>
                        </ListGroup>
                        <div
                            className="card-footer text-center border-top"
                            onClick={handleToggle}
                        >
                            <Link className="card-link d-block" to="/notificaciones">
                                Ver todas
                            </Link>
                        </div>
                    </Card>
                </Dropdown.Menu>
            </Dropdown>

        </div>
    );
};

const NotificationSkeleton = () => {
    return (
        <>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={20} height={20} />
                </Col>
                <Col className="col-11 ">
                    <Skeleton height={20} width="100%" />
                </Col>
            </Row>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={20} height={20} />
                </Col>
                <Col className="col-11 ">
                    <Skeleton height={20} width="100%" />
                </Col>
            </Row>

            <Row
                style={{ border: 'none' }}
                className={classNames(
                    'p-1 shadow-none  d-flex align-items-center rounded-0',
                    'notification-skeleton'
                )}
            >
                <Col className="col-1 pe-0">
                    <Skeleton circle width={20} height={20} />
                </Col>
                <Col className="col-11 ">
                    <Skeleton height={20} width="100%" />
                </Col>
            </Row>
        
        </>
        
    );
};

NotificationDropdown.propTypes = {
    notificaciones: PropTypes.array,
    setIdLead: PropTypes.func,
    setShowModalSeguimientoLead: PropTypes.func,
    isAllRead: PropTypes.number,
    setIsAllRead: PropTypes.func,
    hayNotificacionesNoLeidas: PropTypes.func,
    obtenerNotificaciones: PropTypes.func,
    lastNotf: PropTypes.number,
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func
};

export default NotificationDropdown;
